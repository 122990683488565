import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../../../../assets/images/BUTTON_Back.svg";
import Loader from "../../../../components/Loader";
import AlertComponent from "../../../../components/alert";
import { showSnackbar } from "../../../../slice/snackbarSlice";
import { getInstructionType } from "../../../../thunks/instruction";
import {
  getProductById,
  getProductDisbursementAsPerInstructionType,
  getProductQuestionnaireAsPerInstructionType,
  getProductTaskAsPerInstructionType,
  updateProduct,
} from "../../../../thunks/product";
import {
  NOTIFICATION_MESSAGE,
  VALIDATION_MSG,
} from "../../../../utils/constant";
import { NumberValidation, getUniqueArray } from "../../../../utils/util";
import AddTask from "./AddTaskDialog";
import { formValidation } from "./formValidation";

const UpdateProduct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const instructionTypes = useSelector(
    (state) => state?.instruction?.instructionTypeData
  );
  const productQuestinnaire = useSelector(
    (state) => state?.product?.productQuestionnaire
  );
  const productTask = useSelector((state) => state?.product?.productTasks);
  const disbursementData = useSelector(
    (state) => state?.product?.disbursementData
  );
  const [isShowUpdateTaskDialog, setShowUpdateTaskDialog] = useState(false);
  const [productPricing, setProductPricing] = useState([]);
  const [filterCampignType, setfilterCampignType] = useState([]);
  const [productData, setProductData] = useState({
    name: "",
    description: "",
    isActive: null,
    instructionType: "",
    standardFees: "",
    perVat: "",
    quantityForTaskOrDocuments: null,
    ProductQuestionnaire: [],
    InstructionTypeProduct: [],
    TaskProduct: [],
    ProductDisbursementFeesLookup: [],
  });
  const [isLoader, setLoader] = useState(false);
  const [isError, setError] = useState(false);
  const [isDisableDialog, setDisableDialog] = useState(false);
  const [isUpdateProductDialog, setUpdateProductDialog] = useState(false);

  /* Method to get instruction type */
  const getInstructionTypeData = async () => {
    try {
      const response = dispatch(getInstructionType()).unwrap();
    } catch (error) {}
  };

  /* Method to change instruction type -->
   get ProductQuestionnaire, Task Data, disbursement data */
  const handleChangeInstructionType = (value) => {
    const tempInstructionTypePayload = {
      instructionTypeId: value,
    };
    setProductData({
      ...productData,
      InstructionTypeProduct: [tempInstructionTypePayload],
      ProductQuestionnaire: [],
      TaskProduct: [],
    });
    getProductQuestionnaireData(value);
    getProductTaskData(value);
    getProductDisbursementData(value);
  };

  /* Method to get product questionnaire data */
  const getProductQuestionnaireData = async (value) => {
    try {
      const response = dispatch(
        getProductQuestionnaireAsPerInstructionType(value)
      ).unwrap();
    } catch (error) {}
  };

  /* Method to get product disbursment data */
  const getProductDisbursementData = async (value) => {
    try {
      const response = dispatch(
        getProductDisbursementAsPerInstructionType(value)
      );
    } catch (error) {}
  };

  /* Method to get product task data */
  const getProductTaskData = async (value) => {
    try {
      const response = dispatch(
        getProductTaskAsPerInstructionType(value)
      ).unwrap();
    } catch (error) {}
  };

  /* Method to change form fields */
  const handleChangeFormFields = (value, key) => {
    setProductData({ ...productData, [key]: value });
  };

  /* Methond to change product disbursement */
  const handleChangeProductDisbursement = (value) => {
    setProductData({ ...productData, ProductDisbursementFeesLookup: value });
  };

  /* Method to change product questionnaire */
  const handleChangeProductQuestionnaire = (value) => {
    setProductData({ ...productData, ProductQuestionnaire: value });
  };

  /* Method to change product task */
  const handleChangeProductTask = (value) => {
    setProductData({ ...productData, TaskProduct: value });
  };

  /* Method to open confirm update product Dialog */
  const handleOpenConfirmUpdateProduct = () => {
    setUpdateProductDialog(true);
  };

  /* Methond to update product */
  const handleClickUpdateProduct = async () => {
    setUpdateProductDialog(false);
    const isValidForm = formValidation(productData);
    let wasNull = false;
    for (var i in productPricing) {
      if (
        productPricing[i]?.sourceTypeStandardFees &&
        !NumberValidation(productPricing[i]?.sourceTypeStandardFees)
      ) {
        wasNull = true;
      }
    }
    if (!isValidForm || wasNull) {
      setError(true);
    } else {
      setError(false);

      // Converting productPricing sourceTypeStandardFees to number
      productPricing?.length > 0 &&
        productPricing?.forEach((obj) => {
          if (obj?.sourceTypeStandardFees !== null) {
            obj.sourceTypeStandardFees = Number(obj?.sourceTypeStandardFees);
          }
        });

      const payload = {
        id: Number(params?.id),
        name: productData?.name,
        standardFees: Number(productData?.standardFees) || 0,
        perVat: Number(productData?.perVat) || 0,
        ProductPricing: productPricing,
        quantityForTaskOrDocuments:
          Number(productData?.quantityForTaskOrDocuments) || 0,
      };
      try {
        setLoader(true);
        const response = await dispatch(updateProduct(payload)).unwrap();
        if (response) {
          setLoader(false);
          dispatch(
            showSnackbar({
              show: true,
              message: NOTIFICATION_MESSAGE?.PRODUCT_UPDATE_SUCCESS,
              severity: "success",
            })
          );
        }
      } catch (error) {
        setLoader(false);
        setUpdateProductDialog(false);
        dispatch(
          showSnackbar({
            show: true,
            message: `${error?.message}`,
            severity: "error",
          })
        );
      }
    }
  };

  /* Confirmation Method to diable product  */
  const handleDisableProduct = () => {
    setDisableDialog(true);
  };

  /* Disble product success method */
  const handleSuccessDisable = async () => {
    try {
      const payload = {
        id: Number(params?.id),
        name: productData?.name,
        isActive: productData?.isActive === true ? false : true,
      };
      setLoader(true);
      const response = await dispatch(updateProduct(payload)).unwrap();
      if (response) {
        setLoader(false);
        dispatch(
          showSnackbar({
            show: true,
            message: NOTIFICATION_MESSAGE?.PRODUCT_DEACTIVATE_SUCCESS,
            severity: "success",
          })
        );
        navigate("/app/manage/products/list");
      }
    } catch (error) {
      setLoader(false);
      dispatch(
        showSnackbar({
          show: true,
          message: `${error?.message}`,
          severity: "error",
        })
      );
      navigate("/app/manage/products/list");
      setDisableDialog(false);
    }
  };

  /* Method to get product data by ID */
  const getProductByIdData = async () => {
    try {
      setLoader(true);
      const response = await dispatch(getProductById(params?.id)).unwrap();
      if (response?.id) {
        const tempInstructionTypePayload = {
          id: response?.InstructionTypeProduct[0]?.instructionTypeId,
          name: response?.InstructionTypeProduct[0]?.instructionTypeProductName,
        };
        const tempProductDisbursementFeesLookup = [];
        if (response?.ProductDisbursementFeesLookup?.length > 0) {
          response?.ProductDisbursementFeesLookup.map((d) => {
            tempProductDisbursementFeesLookup?.push({
              id: d?.id,
              name: d?.DisbursementFeesLookupName,
            });
          });
        }

        let tempTaskProduct = [];
        if (response?.TaskProduct?.length > 0) {
          // get unique values
          const arrayUniqueByKey = getUniqueArray(response?.TaskProduct, "id");
          arrayUniqueByKey?.map((d) => {
            tempTaskProduct?.push({
              taskId: d?.id,
              name: d?.taskName,
              id: d?.taskId,
            });
          });
        }

        const productQuestionnaireToSetState = [];
        if (response?.ProductQuestionnaire?.length > 0) {
          response?.ProductQuestionnaire.map((d) => {
            productQuestionnaireToSetState.push({
              id: d?.questionnaireId,
              name: d?.questionnaireName,
            });
          });
        }
        setProductData({
          ...productData,
          name: response?.productName,
          description: response?.description,
          isActive: response?.isActive,
          standardFees: response?.standardFees || 0,
          perVat: response?.perVat || 0,
          quantityForTaskOrDocuments: response?.quantityForTaskOrDocuments || 0,
          ProductQuestionnaire: productQuestionnaireToSetState,
          InstructionTypeProduct: [tempInstructionTypePayload],
          TaskProduct: tempTaskProduct || [],
          ProductDisbursementFeesLookup:
            tempProductDisbursementFeesLookup || [],
        });
        setProductPricing(response?.ProductPricing || []);
        setfilterCampignType(response?.ProductPricing || []);
        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  /* Method to change product pricing */
  const handleChangeProductPricing = (e, id, key) => {
    setProductPricing((prevItems) =>
      prevItems.map((item) =>
        item.sourceTypeLookupId === id ? { ...item, [key]: e } : item
      )
    );
    setfilterCampignType((prevItems) =>
      prevItems.map((item) =>
        item.sourceTypeLookupId === id ? { ...item, [key]: e } : item
      )
    );
  };

  // Method to search source type
  const handleChangeSearch = (search) => {
    if (search?.trim()?.length === 0) {
      setfilterCampignType(productPricing);
    } else {
      const filtered = productPricing?.filter((item) =>
        item?.sourceType?.toLowerCase()?.includes(search?.toLowerCase())
      );
      setfilterCampignType(filtered);
    }
  };

  useEffect(() => {
    getProductByIdData();
    getInstructionTypeData();
  }, []);
  return (
    <>
      {/* Loader component */}
      {isLoader && <Loader />}
      {/* Disbale Product confirmation dialog */}
      {isDisableDialog && (
        <AlertComponent
          open={isDisableDialog}
          handleSuccess={handleSuccessDisable}
          handleClose={() => setDisableDialog(false)}
          message={`Are you sure, want to ${
            productData?.isActive === true ? "Deactivate" : "Activate"
          } product?`}
        />
      )}

      {/* Update Product confirmation dialog */}
      {isUpdateProductDialog && (
        <AlertComponent
          open={isUpdateProductDialog}
          handleSuccess={handleClickUpdateProduct}
          handleClose={() => setUpdateProductDialog(false)}
          message={`Are you sure, want to update product?`}
        />
      )}

      <Grid container spacing={2} justify="flex-start" pl={5} pr={5}>
        {/* ADD NEW PRODUCT BUTTON */}
        <Grid item xs={12}>
          <Typography className="serif_display_regular_26">
            <img
              src={BackButton}
              onClick={() => navigate("/app/manage/products/list")}
              style={{
                cursor: "pointer",
                marginRight: "10px",
              }}
            />
            Manage Product
          </Typography>
        </Grid>

        {/* Product Name */}
        <Grid item xs={2.5} mb={1}>
          <TextField
            className="inputTextField normal_normal_600_12_Manropee"
            required
            label="Product Name"
            placeholder="Product Name"
            fullWidth
            size="small"
            value={productData?.name}
            InputProps={{
              readOnly: true,
            }}
            onChange={(e) => handleChangeFormFields(e?.target?.value, "name")}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            error={isError && !productData?.name}
            helperText={
              isError && !productData?.name && VALIDATION_MSG.REQUIRED
            }
          />
        </Grid>

        {/* Instruction Type */}
        <Grid item xs={2.5} mb={1}>
          <FormControl
            fullWidth
            required
            error={isError && !productData?.InstructionTypeProduct[0]?.id}
          >
            <InputLabel
              className="normal_normal_600_12_Manropee"
              style={{
                lineHeight: "2.0375",
                marginLeft: "-12px",
                color: "#01ce3f",
              }}
              shrink={true}
            >
              Instruction Type
            </InputLabel>
            <Select
              disabled
              className="formControlField"
              value={productData?.InstructionTypeProduct[0]?.id || null}
              label="Instruction Type"
              onChange={(e) => handleChangeInstructionType(e?.target?.value)}
              variant="standard"
              size="small"
            >
              {instructionTypes?.length > 0 &&
                instructionTypes.map((d) => (
                  <MenuItem key={d?.id} value={d?.id}>
                    {d?.name}
                  </MenuItem>
                ))}
            </Select>
            {isError && !productData?.InstructionTypeProduct[0]?.id && (
              <FormHelperText className="red">
                {VALIDATION_MSG.REQUIRED}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        {/* Description */}
        <Grid item xs={5} mb={1}>
          <TextField
            className="inputTextField normal_normal_600_12_Manropee"
            fullWidth
            id="standard-multiline-flexible"
            label="Product Description"
            multiline
            maxRows={4}
            variant="standard"
            value={productData?.description}
            onChange={(e) =>
              handleChangeFormFields(e?.target?.value, "description")
            }
            InputLabelProps={{ shrink: true }}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>

        {/* Standared Fees */}
        <Grid item xs={2.5} mb={1}>
          <TextField
            className="inputTextField normal_normal_600_12_Manropee"
            required
            label="Standard Fees"
            placeholder="Standard Fees"
            fullWidth
            size="small"
            value={productData?.standardFees}
            onChange={(e) =>
              handleChangeFormFields(e?.target?.value, "standardFees")
            }
            InputLabelProps={{ shrink: true }}
            variant="standard"
            error={
              (isError && !productData?.standardFees) ||
              (isError && !NumberValidation(productData?.standardFees))
            }
            helperText={
              (isError &&
                !productData?.standardFees &&
                VALIDATION_MSG.REQUIRED) ||
              (isError &&
                !NumberValidation(productData?.standardFees) &&
                VALIDATION_MSG.NUM_ONLY)
            }
          />
        </Grid>

        {/* VAT In % */}
        <Grid item xs={2.5} mb={1}>
          <TextField
            className="inputTextField normal_normal_600_12_Manropee"
            required
            label="VAT In %"
            placeholder="VAT In %"
            fullWidth
            size="small"
            value={productData?.perVat}
            onChange={(e) => handleChangeFormFields(e?.target?.value, "perVat")}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            error={
              (isError && !productData?.perVat) ||
              (isError && !NumberValidation(productData?.perVat))
            }
            helperText={
              (isError && !productData?.perVat && VALIDATION_MSG.REQUIRED) ||
              (isError &&
                !NumberValidation(productData?.perVat) &&
                VALIDATION_MSG.NUM_ONLY)
            }
          />
        </Grid>

        {/* Number of  Document */}
        <Grid item xs={2.5} mb={1}>
          <TextField
            required
            className="inputTextField normal_normal_600_12_Manropee"
            label="Number of  Document"
            placeholder="Number of  Document"
            fullWidth
            size="small"
            value={productData?.quantityForTaskOrDocuments}
            onChange={(e) =>
              handleChangeFormFields(
                e?.target?.value,
                "quantityForTaskOrDocuments"
              )
            }
            InputLabelProps={{ shrink: true }}
            variant="standard"
            error={
              (isError && !productData?.quantityForTaskOrDocuments) ||
              (isError &&
                !NumberValidation(productData?.quantityForTaskOrDocuments))
            }
            helperText={
              (isError &&
                !productData?.quantityForTaskOrDocuments &&
                VALIDATION_MSG.REQUIRED) ||
              (isError &&
                !NumberValidation(productData?.quantityForTaskOrDocuments) &&
                VALIDATION_MSG.NUM_ONLY)
            }
          />
        </Grid>

        {/* Product disbursement */}
        <Grid item xs={2.5} mb={1}>
          <FormControl fullWidth>
            <Autocomplete
              readOnly={true}
              multiple
              value={productData?.ProductDisbursementFeesLookup}
              id="tags-outlined"
              options={disbursementData || []}
              getOptionLabel={(option) => option?.name}
              onChange={(event, value) =>
                handleChangeProductDisbursement(value)
              }
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="inputTextField normal_normal_600_12_Manropee"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  size="small"
                  label="Disbursement"
                  placeholder="Select Disbursement"
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4} mb={1}>
          {/* Source Type & Pricing section */}
          <Typography className="normal_normal_bold_18_Manrope dark_grey">
            Campaign Pricing
          </Typography>
          <TextField
            mt={1}
            fullWidth
            className="inputTextField normal_normal_600_12_Manropee"
            placeholder="search..."
            variant="standard"
            onChange={(e) => handleChangeSearch(e?.target?.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {filterCampignType?.length > 0 && (
            <Grid
              container
              spacing={2}
              mt={0}
              className="create-user instruction_dashboard_scrollbar"
              style={{
                height: "300px",
                overflow: "auto",
              }}
              mb={10}
            >
              {filterCampignType?.length > 0 &&
                filterCampignType?.map((d, i) => (
                  <>
                    <Grid item xs={5.5}>
                      <TextField
                        value={d?.sourceTypeStandardFees || ""}
                        className="inputTextField normal_normal_600_12_Manropee"
                        label={`${d?.sourceType} price`}
                        placeholder={`Enter ${d?.sourceType} price`}
                        fullWidth
                        size="small"
                        variant="standard"
                        onChange={(e) =>
                          handleChangeProductPricing(
                            e?.target?.value,
                            d.sourceTypeLookupId,
                            "sourceTypeStandardFees"
                          )
                        }
                        error={
                          isError &&
                          d?.sourceTypeStandardFees &&
                          !NumberValidation(d?.sourceTypeStandardFees)
                        }
                        helperText={
                          isError &&
                          d?.sourceTypeStandardFees &&
                          !NumberValidation(d?.sourceTypeStandardFees) &&
                          VALIDATION_MSG.NUM_ONLY
                        }
                      />
                    </Grid>
                  </>
                ))}
            </Grid>
          )}
        </Grid>

        {/* Questionnaire */}
        <Grid item xs={4} pr={5}>
          <Typography
            mb={2}
            className="normal_normal_bold_18_Manrope dark_grey"
          >
            Questionnaire
          </Typography>
          <Autocomplete
            readOnly={true}
            multiple
            value={productData?.ProductQuestionnaire}
            id="tags-outlined"
            options={productQuestinnaire || []}
            getOptionLabel={(option) => option?.name}
            onChange={(event, value) => handleChangeProductQuestionnaire(value)}
            size="small"
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                className="normal_normal_600_12_Manropee"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                size="small"
                label={
                  <Typography sx={{ color: "#01ce3f" }}>
                    Product Questionnaire
                  </Typography>
                }
                placeholder="Select Product Questionnaire"
              />
            )}
          />
        </Grid>

        {/* Product Tasks */}
        <Grid item xs={4} pr={5} mb={10}>
          <Typography
            mb={2}
            className="normal_normal_bold_18_Manrope dark_grey"
          >
            Tasks
            <Tooltip title="Update Task">
              <IconButton
                onClick={() => {
                  setShowUpdateTaskDialog(true);
                }}
                size="small"
                style={{
                  height: 19,
                  width: 19,
                  marginLeft: 2,
                }}
              >
                <EditIcon sx={{ height: 16, color: "#00ce3f" }} />
              </IconButton>
            </Tooltip>
            {/* Dialog to open create note */}
            {isShowUpdateTaskDialog && (
              <AddTask
                open={isShowUpdateTaskDialog}
                getProductByIdData={getProductByIdData}
                handleClose={() => setShowUpdateTaskDialog(false)}
                data={productData?.TaskProduct || []}
              />
            )}
          </Typography>
          <Autocomplete
            readOnly={true}
            multiple
            value={productData?.TaskProduct}
            id="tags-outlined"
            options={productTask || []}
            getOptionLabel={(option) => option?.name}
            onChange={(event, value) => handleChangeProductTask(value)}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                className="normal_normal_600_12_Manropee"
                size="small"
                label={<Typography sx={{ color: "#01ce3f" }}>Tasks</Typography>}
                placeholder="Select Tasks"
              />
            )}
          />
        </Grid>
      </Grid>

      {/* Product Deactivate button / save button */}

      <Grid container spacing={2} className="Save_Button_Container">
        <Grid
          item
          xs={6}
          sx={{ display: "flex", justifyContent: "space-evenly" }}
          m={1}
        >
          <Grid>
            <Button
              disabled={!productData?.isActive}
              variant="outlined"
              className="deactivate_button"
              onClick={handleDisableProduct}
            >
              Deactivate
            </Button>
          </Grid>
        </Grid>

        <Grid
          item
          xs={5}
          sx={{ display: "flex", justifyContent: "flex-end" }}
          m={1}
        >
          <Button
            disabled={!productData?.isActive}
            className="save_button"
            onClick={handleOpenConfirmUpdateProduct}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default UpdateProduct;
