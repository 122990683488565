import { createAsyncThunk } from "@reduxjs/toolkit";
import getAxios from "../helpers/axiosInterceptor";
import { API_ROUTES_PATHS } from "../utils/constant";
import { getUrlWithQueryParams } from "../utils/util";

// Method to Get EmailTemplate API
export const getEmailTemplates = createAsyncThunk(
  "emailTemplate/list",
  async (query, thunkApi) => {
    try {
      let url = getUrlWithQueryParams(
        API_ROUTES_PATHS.EMAIL_TEMPLATES + "/list",
        query
      );

      let res = await getAxios().get(url);

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get emailTemplate data by ID API
export const getEmailTemplateDataById = createAsyncThunk(
  "emailTemplate/getEmailTemplateDataById",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.EMAIL_TEMPLATES}/${model}`
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to Create emailTemplate API
export const createEmailTemplate = createAsyncThunk(
  "emailTemplate/createEmailTemplate",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(
        API_ROUTES_PATHS.EMAIL_TEMPLATES + "/create",
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to Update emailTemplate API
export const updateEmailTemplateThunk = createAsyncThunk(
  "emailTemplate/updateEmailTemplate",
  async ({ id, ...model }, thunkApi) => {
    try {
      let res = await getAxios().patch(
        API_ROUTES_PATHS.EMAIL_TEMPLATES + "/" + id,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to Add Shared Email API
export const addSharedEmailId = createAsyncThunk(
  "email/addSharedEmailId",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(
        API_ROUTES_PATHS.CREATE_SHARED_EMAIL,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get shared email ID  API
export const getSharedEmailIdData = createAsyncThunk(
  "email/getSharedEmailIdData",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(`${API_ROUTES_PATHS.GET_SHARED_EMAIL}`);
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to Update Shared Email API
export const updateSharedEmailId = createAsyncThunk(
  "email/updateSharedEmailId",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().patch(
        `${API_ROUTES_PATHS.CREATE_SHARED_EMAIL}/${model?.id}`,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Transfer Email API
export const transferEmail = createAsyncThunk(
  "email/transferEmail",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().patch(
        `${
          model?.forCase
            ? API_ROUTES_PATHS.TRANSFER_EMAIL
            : API_ROUTES_PATHS.LEAD_CLIENT
        }/${model?.id}/transferEmail`,
        model?.Data
      );

      const responseData = res?.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);
