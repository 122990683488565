import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Loader from "../../../components/Loader";
import PaginationComponent from "../../../components/PaginationComponent";
import { getCurrentWeekInstruction } from "../../../thunks/dashboard";
import { PAGINATION_LIMIT } from "../../../utils/constant";
import { getColorCodeForQc } from "../../../utils/util";
const CurrentWeeksInstruction = () => {
  //     hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //    states
  const [isLoader, setLoader] = useState(false);
  const [totalCount, setTotalCount] = useState("");
  const [filterPayload, setFilterPayload] = useState({
    page: 1,
    limit: PAGINATION_LIMIT,
    milestone: "",
  });
  const [instructions, setInstructions] = useState([]);

  /* Method to get Current week's Instruction */
  const getCurrentWeeksInstructionData = async (payload = filterPayload) => {
    setLoader(true);
    try {
      const response = await dispatch(
        getCurrentWeekInstruction(payload)
      ).unwrap();
      if (response) {
        setInstructions(response?.thisWeeksInstructions);
        setTotalCount(response?.thisWeeksInstructionCount);
        setLoader(false);
      } else {
        setInstructions([]);
        setTotalCount(0);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      return error;
    }
  };

  //   /* Method to change page --> Pagination */
  const handleChangePage = (event, value) => {
    setFilterPayload({ ...filterPayload, page: value });
    const payload = {
      ...filterPayload,
      page: value,
    };
    getCurrentWeeksInstructionData(payload);
  };
  //Method for filter milestone
  const handleChangeMilestone = (value) => {
    setFilterPayload((prev) => ({ ...prev, milestone: value }));
    const payload = {
      ...filterPayload,
      milestone: value,
    };
    getCurrentWeeksInstructionData(payload);
  };

  // use effect
  useEffect((d) => {
    getCurrentWeeksInstructionData();
  }, []);

  return (
    <>
      {/* Loader Component */}
      {isLoader && <Loader />}
      <Grid
        maxHeight={"90vh"}
        display={"flex"}
        flexDirection={"column"}
        gap={1}
        pb={"15px"}
      >
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "30px",
                },
              }}
            >
              <InputLabel className="normal_normal_medium_14_Manrope dark_grey">
                Select
              </InputLabel>
              <Select
                className="textField_outlined_border"
                label="Task Type"
                value={
                  filterPayload?.milestone === "Prospect"
                    ? "Prospect"
                    : "Instructed"
                }
                onChange={(e) => handleChangeMilestone(e?.target?.value)}
              >
                <MenuItem value={"Prospect"}>New Prospect This Week</MenuItem>
                <MenuItem value={"Instructed"}>
                  New Instructed This Week
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            {/* PAGINATION */}
            <PaginationComponent
              totalCount={totalCount}
              limit={filterPayload?.limit}
              page={filterPayload?.page}
              onChange={handleChangePage}
              siblingCount={0}
              boundaryCount={1}
            />
          </Grid>
        </Grid>

        {/* Things to do List section */}
        {/* Things to do List section */}
        <Grid
          display={"flex"}
          flexDirection={"column"}
          overflow={"auto"}
          className="dashboard-scrollbar"
          gap={1}
          px={2}
          py={1}
        >
          {/* Instruction Task Type === Individual */}
          {instructions?.length > 0 &&
            instructions?.map((data) => (
              <>
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  flexDirection={"row"}
                  style={{
                    padding: "8px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    navigate(`/app/instructions/dashboard/${data?.id}`)
                  }
                >
                  <Grid item xs={5}>
                    {data?.InstructionLeadClient?.length > 0 &&
                      data?.InstructionLeadClient?.map((client) => {
                        return (
                          <Typography className="normal_normal_600_16_Manrope green">
                            {`${client?.LeadClient?.firstName} ${client?.LeadClient?.lastName}`}
                          </Typography>
                        );
                      })}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      padding: "12px",
                    }}
                  >
                    {data?.instructionQcRatingHistory && (
                      <Grid item xs={12} display={"flex"} gap={2} m={1}>
                        <Typography className="normal_normal_bold_14_Manrope dark_grey">
                          QC Accuracy -
                        </Typography>
                        <Typography
                          className="normal_normal_bold_14_Manrope"
                          style={{
                            color: getColorCodeForQc(
                              data?.instructionQcRatingHistory?.colour
                            ),
                          }}
                        >
                          {data?.instructionQcRatingHistory?.colour || ""}
                        </Typography>
                      </Grid>
                    )}
                    {data?.instructionValue != null && (
                      <Grid item xs={12} display={"flex"} gap={2} m={1}>
                        <Typography className="normal_normal_14_Manrope dark_grey">
                          Instruction Fees -
                        </Typography>
                        <Typography>£ {data?.instructionValue}</Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Divider sx={{ border: `1px solid #DCDCE1` }} />
              </>
            ))}
        </Grid>
      </Grid>
    </>
  );
};

export default CurrentWeeksInstruction;
