import React, { Suspense } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Loader from "../../components/Loader";
import Dashboard from "./components/Dashboard/Dashboard";
import InstructionFormList from "./components/InstructionFormList";
import InstructionList from "./components/list";
import { useSelector } from "react-redux";
import { USER_ROLE } from "../../utils/constant";

/* Component to define Instruction Module routes */

const Instructions = () => {
  const Location = useLocation();
  const userData = useSelector((state) => state?.auth?.currentUser);
  return (
    <>
      <div style={{ width: "100%" }}>
        <Routes>
          <Route
            path={`/`}
            element={<Navigate replace="/" to={`${Location.pathname}/list`} />}
          ></Route>
          <Route path={"list"} element={<InstructionList />}></Route>
          {userData?.role !== USER_ROLE?.INTRODUCER && (
            <>
              <Route path={"dashboard/:id"} element={<Dashboard />}></Route>
              <Route
                path={"forms/:id/*"}
                element={
                  <Suspense fallback={<Loader />}>
                    <InstructionFormList />
                  </Suspense>
                }
              ></Route>
            </>
          )}
        </Routes>
      </div>
    </>
  );
};

export default Instructions;
