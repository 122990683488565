import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../../assets/images/BUTTON_Back.svg";
import Loader from "../../../../components/Loader";
import "../../../../custom-bootstrap.scss";
import { showSnackbar } from "../../../../slice/snackbarSlice";
import { getInstructionType } from "../../../../thunks/instruction";
import { getLookups } from "../../../../thunks/lookup";
import {
  createProduct,
  getProductDisbursementAsPerInstructionType,
  getProductQuestionnaireAsPerInstructionType,
  getProductTaskAsPerInstructionType,
} from "../../../../thunks/product";
import {
  NOTIFICATION_MESSAGE,
  VALIDATION_MSG,
} from "../../../../utils/constant";
import { NumberValidation } from "../../../../utils/util";
import { formValidation } from "./formValidation";

const CreateProduct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const instructionTypes = useSelector(
    (state) => state?.instruction?.instructionTypeData
  );
  const productQuestinnaire = useSelector(
    (state) => state?.product?.productQuestionnaire
  );
  const productTask = useSelector((state) => state?.product?.productTasks);
  const disbursementData = useSelector(
    (state) => state?.product?.disbursementData
  );
  const [productPricing, setProductPricing] = useState([]);
  const [filterCampignType, setfilterCampignType] = useState([]);
  const [search, setSearch] = useState(null);
  const [productData, setProductData] = useState({
    name: "",
    description: "",
    isActive: true,
    standardFees: "",
    perVat: "",
    quantityForTaskOrDocuments: null,
    ProductQuestionnaire: [],
    InstructionTypeProduct: [],
    TaskProduct: [],
    ProductDisbursementFeesLookup: [],
  });
  const [selectedTask, setSelectedTask] = useState(null);
  const [isLoader, setLoader] = useState(false);
  const [isError, setError] = useState(false);

  /* Method to get instruction type data */
  const getInstructionTypeData = async () => {
    try {
      const response = dispatch(getInstructionType()).unwrap();
    } catch (error) {}
  };

  /* Method to change instruction type --> Get's ProductQuestionnaire,
  Product Tasks, productDisbursement data
  */
  const handleChangeInstructionType = (value) => {
    const tempInstructionTypePayload = {
      instructionTypeId: value,
    };
    setProductData({
      ...productData,
      InstructionTypeProduct: [tempInstructionTypePayload],
      ProductQuestionnaire: [],
      TaskProduct: [],
    });
    getProductQuestionnaireData(value);
    getProductTaskData(value);
    getProductDisbursementData(value);
  };

  /* Method to get Product Questionnaire data by passing Instruction ID */
  const getProductQuestionnaireData = async (value) => {
    try {
      const response = dispatch(
        getProductQuestionnaireAsPerInstructionType(value)
      ).unwrap();
    } catch (error) {}
  };

  /* Method to get Product Disbursement data by passing Instruction ID */
  const getProductDisbursementData = async (value) => {
    try {
      const response = dispatch(
        getProductDisbursementAsPerInstructionType(value)
      );
    } catch (error) {}
  };

  /* Method to get Product Tasks data by passing Instruction ID */
  const getProductTaskData = async (value) => {
    try {
      const response = dispatch(
        getProductTaskAsPerInstructionType(value)
      ).unwrap();
    } catch (error) {}
  };

  /* Method to handleChange form fields */
  const handleChangeFormFields = (value, key) => {
    setProductData({ ...productData, [key]: value });
  };

  /* Method to handleChange product disbursement */
  const handleChangeProductDisbursement = (value) => {
    setProductData({ ...productData, ProductDisbursementFeesLookup: value });
  };

  /* Method to handleChange product questionnaire */
  const handleChangeProductQuestionnaire = (value) => {
    setProductData({ ...productData, ProductQuestionnaire: value });
  };

  /* Method to handleChange product task */
  const handleChangeProductTask = (value) => {
    const findDuplicate = productData?.TaskProduct?.filter(
      (data) => data?.id === value?.id
    );
    // // If block to handle null and duplicate id's
    if (value === null || findDuplicate?.length > 0) {
      setSelectedTask(null);
      if (findDuplicate?.length > 0) {
        dispatch(
          showSnackbar({
            show: true,
            message: "Task already present",
            severity: "error",
          })
        );
      }
    } else {
      setSelectedTask(value);
      setProductData({
        ...productData,
        TaskProduct: [...productData?.TaskProduct, value],
      });
    }
  };

  /* Method to delete single task */
  const handleDeleteTask = (d) => {
    const filtered = productData?.TaskProduct.filter((obj) => {
      return obj.id !== d?.id;
    });
    setProductData({ ...productData, TaskProduct: filtered });
  };

  /* Method to handleChange Product Pricing */
  const handleChangeProductPricing = (e, id, key) => {
    setProductPricing((prevItems) =>
      prevItems.map((item) =>
        item.sourceTypeLookupId === id ? { ...item, [key]: e } : item
      )
    );
    setfilterCampignType((prevItems) =>
      prevItems.map((item) =>
        item.sourceTypeLookupId === id ? { ...item, [key]: e } : item
      )
    );
  };

  /* Method to handleClick create Product */
  const handleClickCreateProduct = async () => {
    const isValidForm = formValidation(productData);
    // was null is use to validate productPricing number validations
    let wasNull = false;
    for (var i in productPricing) {
      if (
        productPricing[i]?.sourceTypeStandardFees &&
        !NumberValidation(productPricing[i]?.sourceTypeStandardFees)
      ) {
        wasNull = true;
      }
    }
    if (!isValidForm || wasNull) {
      setError(true);
    } else {
      setError(false);
      const productQuestionnaireToPassPayload = [];
      const productDisbursementToPassPayload = [];

      if (productData?.ProductQuestionnaire?.length > 0) {
        productData?.ProductQuestionnaire.map((d) => {
          productQuestionnaireToPassPayload.push({
            questionnaireId: d?.id,
          });
        });
      }

      let taskProductToPassPayload = [];
      if (productData?.TaskProduct?.length > 0) {
        // get unique values
        productData?.TaskProduct?.map((d) => {
          taskProductToPassPayload.push({
            taskId: d?.id,
          });
        });
      }

      if (productData?.ProductDisbursementFeesLookup?.length > 0) {
        productData?.ProductDisbursementFeesLookup.map((d) => {
          productDisbursementToPassPayload.push({
            disbursementFeesLookupId: d?.id,
          });
        });
      }

      // Converting productPricing sourceTypeStandardFees to number
      productPricing?.length > 0 &&
        productPricing?.forEach((obj) => {
          if (obj?.sourceTypeStandardFees !== null) {
            obj.sourceTypeStandardFees = Number(obj?.sourceTypeStandardFees);
          }
        });

      const payload = {
        name: productData?.name.trim(),
        description: productData?.description,
        isActive: true,
        standardFees: Number(productData?.standardFees) || 0,
        perVat: Number(productData?.perVat) || 0,
        quantityForTaskOrDocuments:
          Number(productData?.quantityForTaskOrDocuments) || 0,
        ProductQuestionnaire: productQuestionnaireToPassPayload,
        InstructionTypeProduct: productData?.InstructionTypeProduct,
        TaskProduct: taskProductToPassPayload,
        ProductDisbursementFeesLookup: productDisbursementToPassPayload,
        ProductPricing: productPricing,
      };
      try {
        setLoader(true);
        const response = await dispatch(createProduct(payload)).unwrap();
        if (response) {
          setLoader(false);
          dispatch(
            showSnackbar({
              show: true,
              message: NOTIFICATION_MESSAGE?.PRODUCT_CREATE_SUCCESS,
              severity: "success",
            })
          );
          navigate("/app/manage/products/list");
        }
      } catch (error) {
        setLoader(false);
        dispatch(
          showSnackbar({
            show: true,
            message: `${error?.message}`,
            severity: "error",
          })
        );
        navigate("/app/manage/products/list");
      }
    }
  };

  /* Method to get or map source type into dropdown */
  const mapSourceType = async () => {
    try {
      const lookupKeys = ["Source Type"];
      const response = await dispatch(
        getLookups({ searchStrings: JSON.stringify(lookupKeys) })
      );
      if (response?.payload?.[0]?.Lookup?.length > 0) {
        const tempSourceTypes = [];
        response?.payload?.[0]?.Lookup?.map((d) => {
          tempSourceTypes?.push({
            sourceTypeLookupId: d?.id,
            sourceType: d?.name,
            sourceTypeStandardFees: null,
          });
        });
        setfilterCampignType(tempSourceTypes);
        setProductPricing(tempSourceTypes);
      } else {
        setProductPricing([]);
      }
    } catch (error) {}
  };

  const handleChangeSearch = (search) => {
    if (search?.trim()?.length === 0) {
      setfilterCampignType(productPricing);
    } else {
      const filtered = productPricing?.filter((item) =>
        item?.sourceType?.toLowerCase()?.includes(search?.toLowerCase())
      );
      setfilterCampignType(filtered);
    }
  };

  useEffect(() => {
    getInstructionTypeData();
    mapSourceType();
  }, []);

  return (
    <>
      {isLoader && <Loader />}
      <Grid
        container
        spacing={2}
        justify="flex-start"
        className="create-user"
        pl={5}
        pr={5}
      >
        <Grid item xs={12}>
          <Typography className="serif_display_regular_26 dark_grey">
            <img
              src={BackButton}
              onClick={() => navigate("/app/manage/products/list")}
              style={{
                cursor: "pointer",
                marginRight: "10px",
              }}
            />
            Add a New Product
          </Typography>
        </Grid>

        {/* Product Name */}
        <Grid item xs={2.5} mb={1}>
          <TextField
            className="inputTextField normal_normal_600_12_Manropee"
            required
            label="Product Name"
            placeholder="Product Name"
            fullWidth
            size="small"
            value={productData?.name}
            onChange={(e) => handleChangeFormFields(e?.target?.value, "name")}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            error={isError && !productData?.name}
            helperText={
              isError && !productData?.name && VALIDATION_MSG?.REQUIRED
            }
          />
        </Grid>

        {/* Instruction Type */}
        <Grid item xs={2.5} mb={1}>
          <FormControl
            fullWidth
            required
            className="normal_normal_600_12_Manropee"
            error={
              isError &&
              !productData?.InstructionTypeProduct[0]?.instructionTypeId
            }
          >
            <InputLabel
              className="normal_normal_600_12_Manropee"
              shrink={true}
              style={{ lineHeight: "2.0375", marginLeft: "-12px" }}
            >
              Instruction Type
            </InputLabel>
            <Select
              className="formControlField"
              value={productData?.InstructionTypeProduct[0]?.instructionTypeId}
              label="Instruction Type"
              onChange={(e) => handleChangeInstructionType(e?.target?.value)}
              variant="standard"
              size="small"
            >
              {instructionTypes.length > 0 &&
                instructionTypes.map((d) => (
                  <MenuItem key={d?.id} value={d?.id}>
                    {d?.name}
                  </MenuItem>
                ))}
            </Select>
            {isError &&
              !productData?.InstructionTypeProduct[0]?.instructionTypeId && (
                <FormHelperText className="red">
                  {VALIDATION_MSG.REQUIRED}
                </FormHelperText>
              )}
          </FormControl>
        </Grid>

        {/* Product Description */}
        <Grid item xs={5} mb={1}>
          <TextField
            fullWidth
            className="inputTextField normal_normal_600_12_Manropee"
            label="Product Description"
            placeholder="Product Description"
            multiline
            maxRows={4}
            variant="standard"
            value={productData?.description}
            onChange={(e) =>
              handleChangeFormFields(e?.target?.value, "description")
            }
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        {/* Standared Fees */}
        <Grid item xs={2.5} mb={1}>
          <TextField
            className="inputTextField normal_normal_600_12_Manropee"
            required
            label="Standard Fees"
            placeholder="Standard Fees"
            fullWidth
            size="small"
            error={
              (isError && !productData?.standardFees) ||
              (isError && !NumberValidation(productData?.standardFees))
            }
            helperText={
              (isError &&
                !productData?.standardFees &&
                VALIDATION_MSG.REQUIRED) ||
              (isError &&
                !NumberValidation(productData?.standardFees) &&
                VALIDATION_MSG.NUM_ONLY)
            }
            value={productData?.standardFees}
            onChange={(e) =>
              handleChangeFormFields(e?.target?.value, "standardFees")
            }
            InputLabelProps={{ shrink: true }}
            variant="standard"
          />
        </Grid>

        {/* VAT In % */}
        <Grid item xs={2.5} mb={1}>
          <TextField
            className="inputTextField normal_normal_600_12_Manropee"
            required
            label="VAT In %"
            placeholder="VAT In %"
            fullWidth
            size="small"
            value={productData?.perVat}
            onChange={(e) => handleChangeFormFields(e?.target?.value, "perVat")}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            error={
              (isError && !productData?.perVat) ||
              (isError && !NumberValidation(productData?.perVat))
            }
            helperText={
              (isError && !productData?.perVat && VALIDATION_MSG.REQUIRED) ||
              (isError &&
                !NumberValidation(productData?.perVat) &&
                VALIDATION_MSG.NUM_ONLY)
            }
          />
        </Grid>

        {/* Quantity for Document*/}
        <Grid item xs={2.5} mb={1}>
          <TextField
            required
            className="inputTextField normal_normal_600_12_Manropee"
            label="Number of  Document"
            placeholder="Number of  Document"
            fullWidth
            size="small"
            error={
              (isError && !productData?.quantityForTaskOrDocuments) ||
              (isError &&
                !NumberValidation(productData?.quantityForTaskOrDocuments))
            }
            helperText={
              (isError &&
                !productData?.quantityForTaskOrDocuments &&
                VALIDATION_MSG.REQUIRED) ||
              (isError &&
                !NumberValidation(productData?.quantityForTaskOrDocuments) &&
                VALIDATION_MSG.NUM_ONLY)
            }
            value={productData?.quantityForTaskOrDocuments}
            onChange={(e) =>
              handleChangeFormFields(
                e?.target?.value,
                "quantityForTaskOrDocuments"
              )
            }
            InputLabelProps={{ shrink: true }}
            variant="standard"
          />
        </Grid>

        {/* Disbursement */}
        <Grid item xs={2.5} mb={1}>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              value={productData?.ProductDisbursementFeesLookup}
              id="tags-outlined"
              options={disbursementData || []}
              getOptionLabel={(option) => option?.name}
              onChange={(event, value) =>
                handleChangeProductDisbursement(value)
              }
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="inputTextField normal_normal_600_12_Manropee"
                  variant="standard"
                  size="small"
                  label="Disbursement"
                  placeholder="Select Disbursement"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4} mb={1}>
          {/* Source Type & Pricing section */}
          <Typography className="normal_normal_bold_18_Manrope dark_grey">
            Campaign Pricing
          </Typography>
          <TextField
            mt={1}
            fullWidth
            className="inputTextField normal_normal_600_12_Manropee"
            placeholder="search..."
            variant="standard"
            onChange={(e) => handleChangeSearch(e?.target?.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {filterCampignType?.length > 0 && (
            <Grid
              container
              spacing={2}
              mt={0}
              className="instruction_dashboard_scrollbar"
              style={{
                height: "280px",
                overflow: "auto",
              }}
            >
              {filterCampignType?.length > 0 &&
                filterCampignType?.map((d, i) => (
                  <>
                    <Grid item xs={5.5} key={d?.sourceTypeLookupId}>
                      <TextField
                        value={d?.sourceTypeStandardFees}
                        className="inputTextField normal_normal_600_12_Manropee"
                        label={`${d?.sourceType} price`}
                        placeholder={`Enter ${d?.sourceType} price`}
                        fullWidth
                        size="small"
                        variant="standard"
                        onChange={(e) =>
                          handleChangeProductPricing(
                            e?.target?.value,
                            d.sourceTypeLookupId,
                            "sourceTypeStandardFees"
                          )
                        }
                        error={
                          isError &&
                          d?.sourceTypeStandardFees &&
                          !NumberValidation(d?.sourceTypeStandardFees)
                        }
                        helperText={
                          isError &&
                          d?.sourceTypeStandardFees &&
                          !NumberValidation(d?.sourceTypeStandardFees) &&
                          VALIDATION_MSG.NUM_ONLY
                        }
                      />
                    </Grid>
                  </>
                ))}
            </Grid>
          )}
        </Grid>

        {/* Questionnaire */}
        <Grid item xs={3} pr={5}>
          <Typography
            mb={2}
            className="normal_normal_bold_18_Manrope dark_grey"
          >
            Questionnaire
          </Typography>
          <Autocomplete
            multiple
            value={productData?.ProductQuestionnaire}
            options={productQuestinnaire || []}
            getOptionLabel={(option) => option?.name}
            onChange={(event, value) => handleChangeProductQuestionnaire(value)}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                className="normal_normal_600_12_Manropee"
                variant="outlined"
                size="small"
                label="Product Questionnaire"
                placeholder="Select Product Questionnaire"
              />
            )}
          />
        </Grid>

        {/* Tasks */}
        <Grid item xs={5} pr={5} mb={10}>
          <Typography
            mb={2}
            className="normal_normal_bold_18_Manrope dark_grey"
          >
            Tasks
          </Typography>
          <Autocomplete
            // multiple
            value={selectedTask || null}
            options={productTask || []}
            getOptionLabel={(option) => option?.name}
            onChange={(event, value) => handleChangeProductTask(value)}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                className="normal_normal_600_12_Manropee"
                size="small"
                label="Tasks"
                placeholder="Select Tasks"
              />
            )}
          />

          <>
            {productData?.TaskProduct?.length > 0 ? (
              <>
                <Grid item xs={12} mt={2}>
                  <Grid
                    style={{
                      border: "1px solid #dcdce1",
                      borderRadius: "6px",
                      padding: "3px",
                    }}
                  >
                    {productData?.TaskProduct?.map((d, ind) => (
                      <Chip
                        style={{ margin: 6 }}
                        key="ind"
                        label={d?.name || "NA"}
                        onDelete={() => handleDeleteTask(d)}
                      />
                    ))}
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid item xs={12} mt={2}>
                <Typography>No tasks added.</Typography>
              </Grid>
            )}
          </>
        </Grid>
      </Grid>

      {/* Save Button */}
      <Grid
        style={{
          marginTop: "auto",
        }}
      >
        <Grid
          item
          xs={11}
          sx={{ display: "flex", justifyContent: "flex-end" }}
          m={1}
          className="Save_Button_Container"
        >
          <Button className="save_button" onClick={handleClickCreateProduct}>
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default CreateProduct;
