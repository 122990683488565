import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Loader from "../../../../components/Loader";
import { showSnackbar } from "../../../../slice/snackbarSlice";
import { updateCampaignData, xeroOptionSet } from "../../../../thunks/lookup";
import { NOTIFICATION_MESSAGE } from "../../../../utils/constant";
import AlertComponent from "../../../../components/alert";

const UpdateCampaign = ({
  open,
  handleClose,
  lookupId,
  selectedData,
  handleSuccess,
}) => {
  const dispatch = useDispatch();
  const [isLoader, setLoader] = useState(false);
  const [clientTypeLoader, setClientTypeLoader] = useState(false);
  const [clientType, setClientType] = useState([]);
  const [isUpdateCampaignDialog, setUpdateCampaignDialog] = useState(false);
  const [campaignData, setCampaignData] = useState({
    name: null,
    lookupObj: null,
    isActive: null,
  });

  const handleChangeFormFields = (value, key) => {
    setCampaignData({ ...campaignData, [key]: value });
  };

  const handleUpdateXeroClientType = async () => {
    try {
      setClientTypeLoader(true);
      const response = await dispatch(
        xeroOptionSet({
          query: {
            optionSet: "Client Type",
          },
        })
      ).unwrap();
      if (response?.length > 0) {
        setClientType(response);
        setClientTypeLoader(false);
      } else {
        setClientType([]);
        setClientTypeLoader(false);
      }
    } catch (error) {
      setClientTypeLoader(false);
      return error;
    }
  };

  const handleOpenCampaignDialog = () => {
    setUpdateCampaignDialog(true);
  };

  const handleUpdateCampaign = async () => {
    setUpdateCampaignDialog(false);
    try {
      setLoader(true);
      const payload = {
        id: lookupId,
        lookupId: selectedData?.id,
        lookupObj: campaignData?.lookupObj,
        isActive: campaignData?.isActive,
        name: campaignData?.name,
      };
      const response = await dispatch(updateCampaignData(payload)).unwrap();
      if (response) {
        setLoader(false);
        dispatch(
          showSnackbar({
            show: true,
            message: NOTIFICATION_MESSAGE?.CAMPAIGN_UPDATE_SUCCESS,
            severity: "success",
          })
        );
        handleClose();
        handleSuccess();
      } else {
        setLoader(false);
        setUpdateCampaignDialog(false);
        handleClose();
      }
    } catch (error) {
      setUpdateCampaignDialog(false);
      handleClose();
      dispatch(
        showSnackbar({
          show: true,
          message: `${error?.message}`,
          severity: "error",
        })
      );
    }
  };

  useEffect(() => {
    if (selectedData?.id) {
      setCampaignData({
        ...campaignData,
        name: selectedData?.name,
        lookupObj: selectedData?.lookupObj,
        isActive: selectedData?.isActive,
      });
    }
  }, [selectedData]);
  return (
    <>
      {/* Update Campaign Dialog */}
      {isUpdateCampaignDialog && (
        <AlertComponent
          open={isUpdateCampaignDialog}
          handleSuccess={handleUpdateCampaign}
          handleClose={() => setUpdateCampaignDialog(false)}
          message={"Are you sure you want to update campaign?"}
        />
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
        PaperProps={{ sx: { borderRadius: "28px", paddingBottom: 0 } }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        {/* Loader Component */}
        {isLoader && <Loader />}
        <DialogContent>
          <Grid container spacing={3}>
            {/* Create Task Label Section */}
            <Grid item xs={12}>
              <Typography
                className="serif_display_regular_26 green"
                textAlign={"center"}
              >
                Campaign
              </Typography>
            </Grid>

            {/* Campaign Name Field Section */}
            <Grid item xs={6}>
              <TextField
                className="inputTextField normal_normal_600_12_Manropee"
                label="Campaign Name"
                placeholder="Campaign Name"
                disabled
                fullWidth
                required
                size="small"
                value={campaignData?.name || null}
                onChange={(e) =>
                  handleChangeFormFields(e?.target?.value, "name")
                }
                // error={isError && !taskData?.name}
                // helperText={
                //   isError && !taskData?.name && VALIDATION_MSG.REQUIRED
                // }
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>

            {/* Is Commission Applicable? section */}
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#00CE3F",
                      "&.Mui-checked": {
                        color: "#00CE3F",
                      },
                    }}
                    checked={campaignData?.isActive || false}
                    onChange={(e) =>
                      handleChangeFormFields(e?.target?.checked, "isActive")
                    }
                  />
                }
                label="Is Active?"
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                className="inputTextField normal_normal_600_12_Manropee"
                disabled
                label="Xero Client Type"
                placeholder="Xero Client Type"
                fullWidth
                required
                size="small"
                value={campaignData?.lookupObj?.Name}
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>

            {/* Xero client type mapping section */}
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                onOpen={() =>
                  clientType?.length > 0 ? null : handleUpdateXeroClientType()
                }
                options={clientType || []}
                getOptionLabel={(option) => option?.Name}
                onChange={(event, value) =>
                  handleChangeFormFields(value, "lookupObj")
                }
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    className="normal_normal_600_12_Manropee"
                    size="small"
                    label="Xero Client Type"
                    placeholder="Xero Client Type"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {clientTypeLoader ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            {/* Submit Button */}
            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                className="save_button"
                onClick={handleOpenCampaignDialog}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UpdateCampaign;
