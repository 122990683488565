import NorthIcon from "@mui/icons-material/North";
import SearchIcon from "@mui/icons-material/Search";
import SouthIcon from "@mui/icons-material/South";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import DataGridComponent from "../../../components/DataGridComponent";
import Loader from "../../../components/Loader";
import PaginationComponent from "../../../components/PaginationComponent";
import { getClients } from "../../../thunks/client";
import { getLookups } from "../../../thunks/lookup";
import { getMyUsers } from "../../../thunks/user";
import {
  NOTIFICATION_MESSAGE,
  PAGINATION_LIMIT,
  USER_ROLE,
} from "../../../utils/constant";
import AddClient from "./CreateClient";
import { transferEmail } from "../../../thunks/email_template";
import { showSnackbar } from "../../../slice/snackbarSlice";

const TransferClientEmailDialog = ({ open, onClose, selectedEmailId }) => {
  // hooks
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const instructionStatusList = useSelector((state) => {
    // Map instruction status with client list status filter
    let list = state?.lookupData?.["Instruction Status"];
    return list?.length
      ? list?.reduce(
          (a, obj) => {
            let newList = [...a];
            switch (obj?.name?.toLowerCase()) {
              case "cancelled":
                newList = [...a, { name: "Declined", id: obj?.id }];
                break;
              case "open":
                newList = [...a, { name: "Ongoing Instructions", id: obj?.id }];
                break;
            }
            return newList;
          },
          [{ name: "Setup a Meeting", id: 0 }]
        )
      : [];
  });

  // This method is to fetch data into search autocomplete component.
  const getMyUsersData = async () => {
    try {
      const response = await dispatch(getMyUsers()).unwrap();
      if (response?.length > 0) {
        setMyUsers(response);
      } else {
        setMyUsers([]);
      }
    } catch (error) {}
  };

  // states
  const [leadData, setLeadData] = useState([]);
  const [totalCount, setTotalCount] = useState("");
  const [isLoader, setLoader] = useState(false);
  const [filterPayload, setFilterPayload] = useState({
    page: 1,
    limit: PAGINATION_LIMIT,
    search: "",
    sourceTypeLookupId: null,
    instructionStatusId: null,
    orderByOnCreatedAt: "desc",
  });
  const [timer, setTimer] = useState(null);
  const [sourceType, setSourceType] = useState([]);
  const [myUsers, setMyUsers] = useState([]);
  const [isTransfer, setIsTransfer] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [reason, setReason] = useState("");

  // Client Columns
  const columns = [
    {
      field: "fullName",
      headerName: "Name",
      minWidth: 200,
      sortable: false,
      renderCell: (rowParams) => {
        return (
          <Grid>
            {rowParams.row.firstName} {rowParams.row.lastName}
          </Grid>
        );
      },
    },
    {
      field: "mobileNo",
      headerName: "Contact",
      minWidth: 220,
      sortable: false,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 300,
      sortable: false,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      sortable: false,
      renderCell: (rowParams) => (
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            size="small"
            fullWidth
            variant="outlined"
            className="meeting_log_btn normal_normal_semibold_14_Manrope green"
            style={{
              borderRadius: "24px",
              margin: 1,
              border: "1px solid #00ce3f",
            }}
            onClick={() => handleClickTransferEmail(rowParams)}
          >
            Transfer
          </Button>
        </Grid>
      ),
    },
  ];

  const handleClickTransferEmail = (params) => {
    setSelectedClient(params?.row?.id);
    setIsTransfer(true);
  };

  // DataGrid onRowClick Method

  const onRowClick = (params, type, e) => {
    e?.stopPropagation();
    if (type === "button") {
      navigate("/app/setupNewMeeting", {
        state: {
          data: params?.row || {},
        },
      });
    } else {
      navigate(`/app/client/update-client/${params?.row?.id}`);
    }
  };

  /* Methond to get source type */
  const getSourceTypeData = async () => {
    try {
      const lookupKeys = ["Source Type"];
      const response = await dispatch(
        getLookups({ searchStrings: JSON.stringify(lookupKeys) })
      );
      if (response?.payload?.[0]?.Lookup?.length > 0) {
        let data = response?.payload?.[0]?.Lookup?.map((item) => ({
          ...item,
          value: item?.name,
          label: item?.name,
        }));
        setSourceType(data);
      }
    } catch (error) {}
  };

  /* Method to get client data */
  const getClientData = async (payload = filterPayload) => {
    try {
      setLoader(true);
      const responseData = await dispatch(getClients(payload)).unwrap();

      // for filters
      if (!instructionStatusList?.length) {
        dispatch(
          getLookups({ searchStrings: JSON.stringify(["Instruction Status"]) })
        );
      }

      if (responseData?.findAllLeadClient?.length > 0) {
        // remove current client data from list to avoid showing self data to client in the table - to avoid self transfer of email
        let temp = responseData?.findAllLeadClient || [];
        let filteredData = temp?.filter(
          (person) => person?.id !== Number(params?.id)
        );
        setLeadData(filteredData);
        setTotalCount(responseData?.recordLength);
        setLoader(false);
      } else {
        setLeadData([]);
        setLoader(false);
        setTotalCount(0);
      }
    } catch (error) {
      setLeadData([]);
      setTotalCount(0);
      setLoader(false);
    }
  };

  /* handlesuccess method to get client data after client create from dialog component */
  const handlesucess = () => {
    getClientData();
  };

  /* Handlechange sourece type */
  const handleChangeSourceType = (value) => {
    if (value) {
      setFilterPayload({
        ...filterPayload,
        page: 1,
        sourceTypeLookupId: value.id,
      });
      const payload = {
        ...filterPayload,
        page: 1,
        sourceTypeLookupId: value.id,
      };
      getClientData(payload);
    } else {
      setFilterPayload({
        ...filterPayload,
        page: 1,
        sourceTypeLookupId: null,
      });
      const payload = {
        ...filterPayload,
        page: 1,
        sourceTypeLookupId: null,
      };
      delete payload.sourceTypeLookupId;
      getClientData(payload);
    }
  };

  /* handleChange filter */
  const handleChangeFilter = (key, value) => {
    setFilterPayload({
      ...filterPayload,
      page: 1,
      [key]: value,
    });
    const payload = {
      ...filterPayload,
      page: 1,
      [key]: value,
    };
    getClientData(payload);
  };

  /* handleChange Name */
  const handleChangeName = (value) => {
    setFilterPayload({ ...filterPayload, search: value, page: 1 });
    if (value?.length >= 3) {
      clearTimeout(timer);
      const newTimer = setTimeout(() => {
        const payload = {
          ...filterPayload,
          page: 1,
          search: value,
        };
        getClientData(payload);
      }, 500);
      setTimer(newTimer);
    }

    if (value?.length === 0) {
      clearTimeout(timer);
      const newTimer = setTimeout(() => {
        const payload = {
          ...filterPayload,
          page: 1,
          search: value,
        };
        getClientData(payload);
      }, 500);
      setTimer(newTimer);
    }
  };

  const successTransferEmail = async () => {
    const payload = {
      Data: {
        reason: reason,
        transferToId: selectedClient,
        emailId: selectedEmailId,
      },
      forCase: false,
      id: Number(params?.id),
    };
    setIsTransfer(false);
    try {
      setLoader(true);
      const response = await dispatch(transferEmail(payload)).unwrap();
      if (response) {
        setLoader(false);
        dispatch(
          showSnackbar({
            show: true,
            message: NOTIFICATION_MESSAGE?.SUCCESS_TRANSFER_MAIL,
            severity: "success",
          })
        );
        onClose();
      }
    } catch (error) {
      setLoader(false);
      dispatch(
        showSnackbar({
          show: true,
          message: error?.message,
          severity: "error",
        })
      );
      onClose();
    }
  };

  /* HandleChange page --> for pagination */
  const handleChangePage = (event, value) => {
    setFilterPayload({ ...filterPayload, page: value });
    const payload = {
      ...filterPayload,
      page: value,
    };
    getClientData(payload);
  };

  /* handle click for on created at filter method */
  const handleOnCreatedAtFilter = (params) => {
    let tempFilterState = null;
    if (filterPayload?.orderByOnCreatedAt === "desc") {
      tempFilterState = "asc";
    } else {
      tempFilterState = "desc";
    }
    setFilterPayload({
      ...filterPayload,
      orderByOnCreatedAt: tempFilterState,
      page: 1,
    });
    const payload = {
      ...filterPayload,
      orderByOnCreatedAt: tempFilterState,
      page: 1,
    };
    getClientData(payload);
  };

  useEffect(() => {
    getClientData();
    getSourceTypeData();
    getMyUsersData();
  }, []);

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth={"md"} fullWidth={true}>
        {isTransfer && (
          <Dialog
            sx={{ border: 1 }}
            open={open}
            fullWidth={true}
            maxWidth={"sm"}
          >
            <DialogTitle>
              <Grid textAlign={"center"}>Reason for Transfer</Grid>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    label="Add Reason"
                    fullWidth
                    placeholder="Add Reason"
                    multiline
                    rows={10}
                    value={reason || ""}
                    onChange={(e) => setReason(e?.target?.value)}
                    // error={isError && !note}
                    // helperText={
                    //   isError && !note && "Required note or document."
                    // }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 10,
                  }}
                >
                  <Button
                    className="cancel_button"
                    onClick={() => {
                      setIsTransfer(false);
                      setReason("");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!reason.length}
                    className="save_button"
                    onClick={successTransferEmail}
                  >
                    Send
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        )}
        <Grid
          container
          spacing={2}
          style={{ marginTop: "0px", padding: "10px" }}
        >
          {/* Loader Component */}
          {isLoader && <Loader />}

          {/* Filter Container */}
          <Grid item xs={2}>
            <TextField
              fullWidth
              placeholder="Search"
              className="textField_outlined_border"
              size="small"
              value={filterPayload?.search}
              onChange={(e) => handleChangeName(e?.target?.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "30px",
                  height: 38,
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          {/* Source Type */}
          <Grid item xs={2}>
            <Autocomplete
              options={sourceType}
              onChange={(event, value) => handleChangeSourceType(value)}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="normal_normal_medium_14_Manropee textField_outlined_border"
                  variant="outlined"
                  size="small"
                  label="Source Type"
                  placeholder="Select Source Type"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "30px",
                      height: 38,
                    },
                  }}
                />
              )}
            />
          </Grid>

          {/* Status */}

          <Grid item xs={2}>
            <FormControl
              fullWidth
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "30px",
                },
              }}
            >
              <InputLabel className="normal_normal_medium_14_Manrope dark_grey">
                Status
              </InputLabel>
              <Select
                className="textField_outlined_border"
                label="Status"
                onChange={(e) =>
                  handleChangeFilter("instructionStatusId", e?.target?.value)
                }
              >
                <MenuItem>--</MenuItem>
                {instructionStatusList?.length > 0 &&
                  instructionStatusList.map((d) => (
                    <MenuItem
                      key={d?.id}
                      value={d?.id}
                      className="normal_normal_medium_14_Manrope dark_grey"
                    >
                      {d?.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Introduced By */}

          <Grid item xs={2}>
            {/* <Autocomplete
            options={myUsers || []}
            onChange={(e, value) =>
              handleChangeFilter(
                "introducedByOrTo",
                value ? [value?.id] : undefined
              )
            }
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                className="normal_normal_medium_14_Manropee textField_outlined_border"
                variant="outlined"
                size="small"
                label="Introduced To"
                placeholder="Select Consultant"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "30px",
                    height: 38,
                  },
                }}
              />
            )}
          /> */}

            <Autocomplete
              options={myUsers || []}
              getOptionLabel={(option) =>
                `${option?.firstName || ""} ${option?.lastName || ""}`
              }
              onChange={(e, value) =>
                handleChangeFilter(
                  "introducedByOrTo",
                  value ? [value?.id] : undefined
                )
              }
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  {...params}
                  className="normal_normal_medium_14_Manropee inputTextField border_color"
                  size="small"
                  label="Introduced By"
                  placeholder="Introduced By"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: 24,
                      borderRadius: "24px",
                    },
                  }}
                />
              )}
            />
          </Grid>

          {/* Client Table */}

          <Grid item xs={12} style={{ width: "100%", height: "75vh" }}>
            <DataGridComponent
              columns={columns}
              rows={leadData || []}
              onRowClick={(params) => {}}
            />
          </Grid>

          {/* PAGINATION */}
          <PaginationComponent
            totalCount={totalCount}
            limit={filterPayload?.limit}
            page={filterPayload?.page}
            onChange={handleChangePage}
          />
        </Grid>
      </Dialog>
    </>
  );
};
export default TransferClientEmailDialog;
