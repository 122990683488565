import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { USER_ROLE } from "../../../utils/constant";
import { getClientNamesFromProspectString } from "../../../utils/util";

const ClientInstructionDetails = () => {
  // hooks
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state?.auth?.currentUser);
  const { clientUserData: formData } = useSelector((store) => store?.client);

  // method for redirect to meeting screen
  const handleClickMeeting = (data) => {
    const payload1 = {
      applicationType: data?.applicationType || null,
      sourceType: data?.sourceTypeLookupId || null,
      sourceSubType: data?.sourceSubType || [],
      instructionType: data?.instructionType || null,
      createdAt: data?.createdAt || null,
      firstName: formData?.firstName || [],
      middleName: formData?.middleName || [],
      lastName: formData?.lastName || [],
      email: formData?.email || [],
      mobileNo: formData?.mobileNo || [],
      postalCode: formData?.postalCode || [],
    };
    if (payload1) {
      navigate("/app/setupNewMeeting", { state: { data: payload1 } });
    }
  };

  const handleNavigateToInstructionPage = (event, instructionId) => {
    event?.stopPropagation();
    navigate(`/app/instructions/dashboard/${instructionId}`);
  };

  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        backgroundColor: "#E5FAEB",
        height: "100%",
      }}
    >
      <Typography
        className="serif_display_regular_18"
        sx={{ padding: 2, paddingBottom: 0 }}
      >
        Instructions
      </Typography>
      <Grid
        pb={2}
        px={2}
        boxSizing={"border-box"}
        sx={{
          backgroundColor: "#E5FAEB",
          //   flexGrow:1,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          overflowY: "scroll",
          gap: 1,
          margin: 0,
        }}
      >
        {formData?.Instruction?.length ? (
          formData?.Instruction?.map(
            (instructionItem, instructionItemIndex) => {
              return (
                <InstructionCard
                  key={instructionItemIndex}
                  dataObject={instructionItem}
                  handleClickMeeting={handleClickMeeting}
                  userDetails={userDetails}
                  navigateToInstructionPage={(event) =>
                    handleNavigateToInstructionPage(event, instructionItem?.id)
                  }
                />
              );
            }
          )
        ) : (
          <Grid
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            width={"100%"}
            className="normal_normal_16_Manrope dark_grey"
          >
            No Instructions to display
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ClientInstructionDetails;

const InstructionCard = ({
  dataObject,
  handleClickMeeting,
  userDetails,
  navigateToInstructionPage,
}) => {
  let names = getClientNamesFromProspectString(dataObject?.name || "");
  const data = {
    names: names,
    applicationType: dataObject?.applicationType,
    instructionType: dataObject?.instructionType,
    milestone: dataObject?.milestone,
    plansAndProducts:
      dataObject?.InstructionPlanSelections
        ?.InstructionPlanSelectionsAndIndividualProducts,
    instrcutionCost: dataObject?.InstructionPlanSelections?.instrcutionCost,
  };

  return (
    <Grid
      sx={{ backgroundColor: "white", cursor: "pointer" }}
      borderRadius={"16px"}
      padding={1}
      display={"flex"}
      flexDirection={"column"}
      gap={0}
      minWidth={"100%"}
      onClick={
        userDetails?.role === USER_ROLE?.INTRODUCER
          ? null
          : navigateToInstructionPage
      }
    >
      <Grid display={"flex"} flexDirection={"column"} gap={0} width={"100%"}>
        {/* Client Names */}
        <Grid display={"flex"} alignItems={"center"} gap={0.5}>
          <Grid className="normal_normal_600_12_Manrope green" xs={2}>
            Client Name -
          </Grid>{" "}
          <Grid className="normal_normal_16_Manrope" xs={10}>
            {data?.names?.map((c) => c)?.join(" & ")}
          </Grid>
        </Grid>

        {/* Client Names */}
        <Grid display={"flex"} alignItems={"center"} gap={0.5}>
          <Grid className="normal_normal_600_12_Manrope green" xs={2}>
            Application type -
          </Grid>{" "}
          <Grid className="normal_normal_16_Manrope" xs={10}>
            {data?.applicationType}
          </Grid>
        </Grid>

        {/* Client Names */}
        <Grid display={"flex"} alignItems={"center"} gap={0.5}>
          <Grid className="normal_normal_600_12_Manrope green" xs={2}>
            Instruction type -
          </Grid>{" "}
          <Grid className="normal_normal_16_Manrope" xs={10}>
            {data?.instructionType}
          </Grid>
        </Grid>

        {/* Client Names */}
        <Grid display={"flex"} alignItems={"center"} gap={0.5}>
          <Grid className="normal_normal_600_12_Manrope green" xs={2}>
            Milestone -
          </Grid>{" "}
          <Grid className="normal_normal_16_Manrope" xs={10}>
            {data?.milestone}
          </Grid>
        </Grid>

        {/* Client Names */}
        <Grid display={"flex"} alignItems={"center"} gap={0.5}>
          <Grid className="normal_normal_600_12_Manrope green" xs={2}>
            Plan & Products -
          </Grid>{" "}
          <Grid className="normal_normal_16_Manrope" xs={10}>
            {data?.plansAndProducts || "NA"}
          </Grid>
        </Grid>

        {/* Squiggle Fees */}
        <Grid display={"flex"} alignItems={"center"} gap={0.5}>
          <Grid className="normal_normal_600_12_Manrope green" xs={2}>
            Squiggle Fees -
          </Grid>{" "}
          <Grid className="normal_normal_16_Manrope" xs={10}>
            {data?.instrcutionCost != null
              ? `£ ${Math.round(data.instrcutionCost)}`
              : "NA"}
          </Grid>
        </Grid>

        {/* Set Up Meeting Button */}

        {userDetails?.role === USER_ROLE?.INTRODUCER ? null : (
          <Grid
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"flex-start"}
            mt={0}
            className="normal_normal_600_12_Manrope green"
          >
            <Button
              className="set_up_meeting_button"
              onClick={(e) => {
                e?.stopPropagation();
                handleClickMeeting(dataObject);
              }}
            >
              Setup meeting
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
