import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DataGridComponent from "../../../../components/DataGridComponent";
import Loader from "../../../../components/Loader";
import { showSnackbar } from "../../../../slice/snackbarSlice";
import {
  addSharedEmailId,
  getSharedEmailIdData,
  updateSharedEmailId,
} from "../../../../thunks/email_template";

import DeleteIcon from "@mui/icons-material/Delete";
import { VALIDATION_MSG } from "../../../../utils/constant";
import { emailValidation } from "../../../../utils/util";

const AddViewSharedEmail = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const [isLoader, setLoader] = useState(false);
  const [isError, setIsError] = useState(false);
  const [email, setEmail] = useState();
  const [emailAppPassword, setEmailAppPassword] = useState();
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [emailData, setEmailData] = useState([]);

  const COLUMNS = [
    { field: "email", headerName: "Email", minWidth: 350, sortable: false },
    {
      field: "action",
      headerName: "Action",
      minWidth: 200,
      sortable: false,
      headerAlign: "center",
      renderCell: (params) => (
        <Grid width="100%" display={"flex"} justifyContent={"center"}>
          <IconButton onClick={() => handleClickUpdate(params)}>
            <EditIcon />
          </IconButton>

          <IconButton onClick={() => handleDeleteSharedEmail(params)}>
            <DeleteIcon style={{ color: "#ff0000" }} />
          </IconButton>
        </Grid>
      ),
    },
  ];

  //   Method to handle update shared email
  const handleClickUpdate = (params) => {
    setEmail(params?.row?.email || "");
    setEmailAppPassword(params?.row?.emailAppPassword || "");
    setSelectedEmail(params?.row?.id);
  };

  //   Method to handle create shared email
  const handleCreateUpdateSharedEmail = async () => {
    if (!email || !emailValidation(email)) {
      setIsError(true);
    } else {
      setIsError(false);
      try {
        setLoader(true);
        let response = null;
        // Check if selected email is there then update else add
        if (selectedEmail) {
          const payload = { id: selectedEmail, email, emailAppPassword };
          response = await dispatch(updateSharedEmailId(payload)).unwrap();
        } else {
          response = await dispatch(
            addSharedEmailId({ email, emailAppPassword })
          ).unwrap();
        }
        // Check if response is there then show success message
        if (response) {
          setLoader(false);
          dispatch(
            showSnackbar({
              message: `Shared Email ${
                selectedEmail ? "Updated" : "Added"
              }  Successfully`,
              severity: "success",
            })
          );
          setEmail("");
          setEmailAppPassword("");
          setSelectedEmail("");
          getSharedEmail();
        }
      } catch (error) {
        setLoader(false);
        setEmail(null);
        setEmailAppPassword(null);
        setSelectedEmail(null);
        dispatch(
          showSnackbar({
            message: `${error?.message}`,
            severity: "error",
          })
        );
      }
    }
  };

  // Method to get shared email
  const getSharedEmail = async () => {
    try {
      setLoader(true);
      const response = await dispatch(getSharedEmailIdData()).unwrap();
      if (response?.length > 0) {
        setEmailData(response);
        setLoader(false);
      } else {
        setEmailData([]);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      return error;
    }
  };

  //   Method to clear email
  const handleClearEmail = (field) => {
    if (field === "email") {
      setEmail("");
    } else {
      setEmailAppPassword("");
    }
    setSelectedEmail(null);
  };

  //   Method to handle delete shared email
  const handleDeleteSharedEmail = async (params) => {
    try {
      setLoader(true);
      const payload = {
        id: params.row?.id,
        isActive: false,
      };
      const response = await dispatch(updateSharedEmailId(payload)).unwrap();
      if (response) {
        setLoader(false);
        dispatch(
          showSnackbar({
            message: "Shared Email Deleted Successfully",
            severity: "success",
          })
        );
        setEmail("");
        setEmailAppPassword("");
        setSelectedEmail(null);
        getSharedEmail();
      }
    } catch (error) {
      setLoader(false);
      dispatch(
        showSnackbar({
          message: `${error?.message}`,
          severity: "error",
        })
      );
    }
  };

  useEffect(() => {
    getSharedEmail();
  }, [open]);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"sm"}
      PaperProps={{ sx: { borderRadius: "28px", paddingBottom: 0 } }}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle
        className="serif_display_regular_20 green"
        textAlign={"center"}
      >
        Shared Email
      </DialogTitle>
      <DialogContent dividers={true} sx={{ marginRight: "5px" }}>
        {isLoader && <Loader />}

        <Grid container spacing={3} mb={3}>
          <Grid item xs={4.5}>
            <TextField
              value={email}
              fullWidth
              className="inputTextField border_color normal_normal_600_12_Manropee"
              label="Enter Email"
              placeholder="Enter email"
              variant="standard"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              error={isError && (!email || !emailValidation(email))}
              helperText={
                (isError && !email && VALIDATION_MSG?.REQUIRED) ||
                (isError &&
                  !emailValidation(email) &&
                  VALIDATION_MSG.VALID_EMAIL_ID)
              }
              InputProps={{
                autoComplete: "off",
                endAdornment: (
                  <IconButton
                    onClick={() => handleClearEmail("email")}
                    style={{ display: `${selectedEmail ? "block" : "none"}` }}
                    size="small"
                  >
                    <HighlightOffIcon />
                  </IconButton>
                ),
              }}
            />
          </Grid>
          <Grid item xs={4.5}>
            <TextField
              value={emailAppPassword}
              fullWidth
              type="password"
              className="inputTextField border_color normal_normal_600_12_Manropee"
              label="Email app password"
              placeholder="Enter email app password"
              variant="standard"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                setEmailAppPassword(e.target.value);
              }}
              InputProps={{
                autoComplete: "new-password",
                endAdornment: (
                  <IconButton
                    onClick={() => handleClearEmail("emailAppPassword")}
                    style={{ display: `${selectedEmail ? "block" : "none"}` }}
                    size="small"
                  >
                    <HighlightOffIcon />
                  </IconButton>
                ),
              }}
            />
          </Grid>
          <Grid item xs={3} textAlign={"right"}>
            <Button
              className="add_a_new_user_button"
              onClick={handleCreateUpdateSharedEmail}
            >
              {selectedEmail ? "Update" : "Add New"}
            </Button>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            width: "100%",
            height: "80vh",
            overflow: "auto",
          }}
        >
          <DataGridComponent
            columns={COLUMNS}
            rows={emailData}
            onRowClick={() => {}}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AddViewSharedEmail;
