import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Loader from "../components/Loader";
import { WithSideBar } from "../layouts";
import AdminMenu from "../layouts/components/sidebars/adminMenu";
import consultantMenu from "../layouts/components/sidebars/consultantMenu";
import introducerMenu from "../layouts/components/sidebars/introducerMenu";
import officeAdminMenu from "../layouts/components/sidebars/officeAdminMenu";
import CalendlyDashboard from "../pages/Calendly/Dashboard";
import Commissions from "../pages/Commissions";
import ConsultantDashboard from "../pages/ConsultantDashboard";
import Instructions from "../pages/Instructions";
import IntroducerDashboard from "../pages/IntroducerDashboard";
import ManageProfile from "../pages/ManageProfile/ManageProfile";
import OfficeAdminDashboard from "../pages/OfficeAdminDashboard";
import Reports from "../pages/Reports";
import SuperAdminDashboard from "../pages/SuperAdminDashboard";
import Client from "../pages/client";
import Manage from "../pages/manage";
import Support from "../pages/support";
import { getLoginUser } from "../thunks/auth";
import { getLookups } from "../thunks/lookup";
import { USER_ROLE } from "../utils/constant";

const MainApp = (props) => {
  const dispatch = useDispatch();
  const Location = useLocation();
  const userDetails = useSelector((state) => state?.auth?.currentUser);
  const [isLoader, setLoader] = useState(false);

  // Method to call API to get login user details.
  const getUserDetails = async () => {
    try {
      setLoader(true);
      const response = await dispatch(getLoginUser()).unwrap();
      if (response) {
        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (error) {}
  };

  // Method to call API to get lookup data
  const getLookupsByKey = async () => {
    try {
      const lookupKeys = [
        "Role",
        "Gender",
        "Salutation",
        "Country",
        "Employment Status",
        "milestone",
        "Meeting Type",
        "Source Type",
        "slaUnit",
        "TriggerType",
        "Document Type",
        "Template Type",
        "Lead Status",
        "Salutation",
        "UserAccess",
      ];
      const response = await dispatch(
        getLookups({ searchStrings: JSON.stringify(lookupKeys) })
      ).unwrap();
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    getUserDetails();
    getLookupsByKey();
  }, []);

  return (
    <>
      {/* Loader Component */}
      {isLoader && <Loader />}
      {/* Routes if Role === SuperAdmin */}
      {userDetails?.role === USER_ROLE?.SUPER_ADMIN && (
        <WithSideBar sidebarMenu={AdminMenu}>
          <div className="container-fluid main-app-container mx-0">
            <Routes>
              <Route
                path={"/"}
                element={
                  <Navigate replace to={`${Location.pathname}/dashboard`} />
                }
              />
              <Route path={`dashboard/*`} element={<SuperAdminDashboard />} />
              <Route path={`manage/*`} element={<Manage />} />
              <Route path={`instructions/*`} element={<Instructions />} />
              <Route path={`client/*`} element={<Client />} />
              <Route path={`profile/*`} element={<ManageProfile />} />
              <Route path={`tech-support/*`} element={<Support />} />
              <Route
                path={`setupNewMeeting/*`}
                element={<CalendlyDashboard />}
              />
              <Route path={`reports/*`} element={<Reports />} />
            </Routes>
          </div>
        </WithSideBar>
      )}

      {/* Routes if Role === Introducer */}
      {userDetails?.role === USER_ROLE?.INTRODUCER && (
        <WithSideBar sidebarMenu={introducerMenu || []}>
          <div className="container-fluid main-app-container mx-0">
            <Routes>
              <Route
                path={"/"}
                element={
                  <Navigate replace to={`${Location.pathname}/dashboard`} />
                }
              />
              <Route path={`dashboard/*`} element={<IntroducerDashboard />} />
              <Route path={`instructions/*`} element={<Instructions />} />
              <Route path={`client/*`} element={<Client />} />
              <Route path={`commissions/*`} element={<Commissions />} />
              <Route path={`profile/*`} element={<ManageProfile />} />
            </Routes>
          </div>
        </WithSideBar>
      )}

      {/* Routes if Role === Consultant */}
      {userDetails?.role === USER_ROLE?.CONSULTANT && (
        <WithSideBar sidebarMenu={consultantMenu}>
          <div className="container-fluid main-page-consultant-container">
            <Routes>
              <Route
                path={"/"}
                element={
                  <Navigate replace to={`${Location.pathname}/dashboard`} />
                }
              />
              <Route path={`dashboard/*`} element={<ConsultantDashboard />} />
              <Route path={`instructions/*`} element={<Instructions />} />
              <Route path={`commissions/*`} element={<Commissions />} />
              <Route path={`client/*`} element={<Client />} />
              <Route path={`profile/*`} element={<ManageProfile />} />
              <Route
                path={`setupNewMeeting/*`}
                element={<CalendlyDashboard />}
              />
              <Route path={`tech-support/*`} element={<Support />} />
            </Routes>
          </div>
        </WithSideBar>
      )}

      {/* Routes if Role === OfficeAdmin */}
      {userDetails?.role === USER_ROLE?.OFFICE_ADMIN && (
        <WithSideBar sidebarMenu={officeAdminMenu}>
          <div className="container-fluid main-page-consultant-container">
            <Routes>
              <Route
                path={"/"}
                element={
                  <Navigate replace to={`${Location.pathname}/dashboard`} />
                }
              />
              <Route path={`dashboard/*`} element={<OfficeAdminDashboard />} />
              <Route path={`instructions/*`} element={<Instructions />} />
              <Route path={`client/*`} element={<Client />} />
              <Route path={`profile/*`} element={<ManageProfile />} />
              <Route
                path={`setupNewMeeting/*`}
                element={<CalendlyDashboard />}
              />
              <Route path={`reports/*`} element={<Reports />} />
              <Route path={`tech-support/*`} element={<Support />} />
            </Routes>
          </div>
        </WithSideBar>
      )}
    </>
  );
};

export default MainApp;
