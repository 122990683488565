import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddPhotoIcon from "../../../../assets/images/Add_Photo_icon.png";
import BackButton from "../../../../assets/images/BUTTON_Back.svg";
import Loader from "../../../../components/Loader";
import { showSnackbar } from "../../../../slice/snackbarSlice";
import { uploadFile } from "../../../../thunks/documents";
import { getLookups } from "../../../../thunks/lookup";
import { getAllTasks } from "../../../../thunks/task";
import {
  createUser,
  getConsultant,
  getIntroducer,
} from "../../../../thunks/user";
import {
  DATE_FORMAT,
  IS_VAT_VERIFIED_DATA,
  NOTIFICATION_MESSAGE,
  OFFICE_ADMIN_PHONE_NUMBER,
  USER_ROLE,
  VALIDATION_MSG,
  VAT_NUMBER_START,
} from "../../../../utils/constant";
import {
  emailValidation,
  getUniqueArray,
  vatNumberValidation,
} from "../../../../utils/util";
import { formValidation } from "./formValidation";

const CreateUser = () => {
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const lookups = useSelector((state) => state?.lookupData);
  let {
    Role: roles,
    Gender: gender,
    Salutation: salutation,
    Country: country,
    UserAccess: userAccess,
  } = lookups || {};

  const UserAccessLabel = lookups?.UserAccess?.[0]?.label;
  const allConsultantArray = [
    {
      id: 1000,
      firstName: "All",
      lastName: "",
      role: "",
      isActive: true,
      email: "",
      phoneNumber: "",
      scheduling_url: "",
      calendlyUserUri: "",
    },
  ];
  const consultants = useSelector((state) => state?.user?.consultantsData);
  const introducers = useSelector((state) => state?.user?.introducersData);

  const [userData, setUserData] = useState({
    email: "",
    password: "",
    role: "",
    rolesLookupId: "",
    type: "External",
    firstName: "",
    lastName: "",
    middleName: "",
    isActive: null,
    isVatVerified: false,
    dob: new Date(),
    address: {
      street: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
    },
    phoneNumber: "",
    salutation: "",
    gender: "Male",
    ProductUser: [],
    TaskUser: [],
    ConsultantName: [],
    vatNumber: null,
    campaignLookupId: null,
    isCampaignHead: false,
    isCommissionApplicable: true,
    isLogin: true,
    permissions: {
      lookupId: 0,
      isPermission: false,
    },
  });
  const [isError, setIsError] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const [userImage, setUserImage] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedConsultant, setSelectedConsultant] = useState(null);
  const [dateError, setDateError] = React.useState(null);
  const [campaigns, setCampaigns] = useState([]);

  /* Method for handleChange form fields */
  const handleChangeFormFields = (value, key) => {
    if (key === "isCommissionApplicable" && value === false) {
      setUserData({
        ...userData,
        vatNumber: null,
        isVatVerified: false,
        isCommissionApplicable: false,
      });
    } else {
      setUserData({
        ...userData,
        [key]: value,
      });
    }
  };
  /* method to handleChange Address fields */
  const handleChangeAddressFields = (value, key) => {
    setUserData({
      ...userData,
      address: {
        ...userData?.address,
        [key]: value,
      },
    });
  };

  /* method to handleChange Tasks field */
  const handleChangeTasks = (value) => {
    const findDuplicate = userData?.TaskUser.filter(
      (data) => data?.id === value?.id
    );
    // If block to handle null and duplicate id's
    if (value === null || findDuplicate?.length > 0) {
      setSelectedTask(null);
      if (findDuplicate?.length > 0) {
        dispatch(
          showSnackbar({
            show: true,
            message: "Task already present",
            severity: "error",
          })
        );
      }
    } else {
      setSelectedTask(value);
      setUserData({ ...userData, TaskUser: [...userData?.TaskUser, value] });
    }
  };

  /* Method to delete single task */
  const handleDeleteTask = (d) => {
    const filtered = userData?.TaskUser.filter((obj) => {
      return obj.id !== d?.id;
    });
    setUserData({ ...userData, TaskUser: filtered });
  };

  /* method to handleChange Introducers field */
  const handleChangeIntroducers = (value) => {
    const introducers = [];
    if (value) {
      value?.map((d) => {
        introducers?.push({
          id: d?.id,
          firstName: `${d?.firstName}`,
        });
      });
      setUserData({ ...userData, introducerName: introducers });
    }
  };

  /* method to handleChange Consultants field */
  const handleChangeConsultants = (value) => {
    const findDuplicate = userData?.ConsultantName.filter(
      (data) => data?.suserId === value?.id
    );

    if (value?.firstName === "All") {
      const tempConsultant = [];
      consultants?.map((d) => {
        tempConsultant?.push({
          suserId: d?.id || d?.suserId,
          firstName: `${d?.firstName}`,
          lastName: ` ${d?.lastName}`,
        });
      });
      setUserData({ ...userData, ConsultantName: tempConsultant });
    } else if (value === null || findDuplicate?.length > 0) {
      setSelectedConsultant(null);
      if (findDuplicate?.length > 0) {
        dispatch(
          showSnackbar({
            show: true,
            message: "Consultant already present",
            severity: "error",
          })
        );
      }
    } else {
      let consultants = null;
      consultants = {
        suserId: value?.id || value?.suserId,
        firstName: `${value?.firstName}`,
        lastName: ` ${value?.lastName}`,
      };
      setSelectedConsultant(consultants);
      setUserData({
        ...userData,
        ConsultantName: [...userData?.ConsultantName, consultants],
      });
    }
  };

  // method to delete single consultant
  const handleDeleteConsultant = (d) => {
    const filtered = userData?.ConsultantName.filter((obj) => {
      return obj.suserId !== d?.suserId;
    });
    setUserData({ ...userData, ConsultantName: filtered });
  };

  /* Method to Create user */
  const handleClickCreateUser = async () => {
    const isValidForm = formValidation(userData);
    // Checking validation
    if (!isValidForm || !userData?.password || dateError) {
      setIsError(true);
    } else {
      setIsError(false);
      // proceed to create user
      let tasksToPassPayload = [];
      if (userData?.TaskUser?.length > 0) {
        userData?.TaskUser?.map((d) => {
          tasksToPassPayload.push({
            taskId: d?.id,
          });
        });
      }
      const introducersToPassPayload = [];
      if (userData?.ConsultantName?.length > 0) {
        // get unique values
        const arrayUniqueByKey = getUniqueArray(
          userData?.ConsultantName,
          "suserId"
        );
        arrayUniqueByKey?.map((d) => {
          introducersToPassPayload.push({
            suserId: d?.suserId,
          });
        });
      }
      const tempRoleLookupId = roles?.filter(
        (d) => d?.value === userData?.role
      );

      const tempUserAccessLookupId = userAccess?.filter(
        (d) => d?.id === userData?.permissions?.lookupId
      );
      try {
        setLoader(true);
        let uploadFileResponse = "";
        // Logic --> if userImage available
        if (userImage) {
          let formData = new FormData();
          formData.append("myfile", userImage, userImage?.name);
          const payload = {
            formData,
            url: `/file/upload`,
          };
          // Method to upload file and return uploaded file URL
          uploadFileResponse = await dispatch(uploadFile(payload)).unwrap();
        }
        const payload = {
          email: userData?.email.trim(),
          password: userData?.password,
          role: userData?.role,
          rolesLookupId: tempRoleLookupId?.[0]?.id || "",
          type: "External",
          firstName: userData?.firstName,
          lastName: userData?.lastName,
          middleName: userData?.middleName,
          isActive: null,
          isVatVerified: userData?.isVatVerified,
          dob: userData?.dob,
          address: {
            street: userData?.address?.street,
            city: userData?.address?.city,
            state: userData?.address?.state,
            postalCode: userData?.address?.postalCode,
            country: userData?.address?.country,
          },
          phoneNumber: userData?.phoneNumber,
          salutation: userData?.salutation,
          gender: userData?.gender,
          TaskUser: tasksToPassPayload,
          ConsultantName: introducersToPassPayload,
          profilePhotoUrl: uploadFileResponse || "",
          vatNumber: userData?.isVatVerified ? userData?.vatNumber : null,
          isCampaignHead: userData?.isCampaignHead,
          isCommissionApplicable:
            userData?.isCommissionApplicable === "false" ? false : true,
          campaignLookupId: userData?.campaignLookupId?.id,
          isLogin: userData?.isLogin,
          permissions: userData?.permissions?.isPermission ? {
            lookupId: tempUserAccessLookupId?.[0]?.id || 0,
            isPermission: userData?.permissions?.isPermission,
          } : undefined,
        };

        const response = await dispatch(createUser(payload)).unwrap();
        if (response?.data) {
          setLoader(false);
          dispatch(
            showSnackbar({
              show: true,
              message: NOTIFICATION_MESSAGE?.USER_CREATE_SUCCESS,
              severity: "success",
            })
          );
          navigate("/app/manage/users/list");
        }
      } catch (error) {
        setLoader(false);
        dispatch(
          showSnackbar({
            show: true,
            message: `${error?.message}`,
            severity: "error",
          })
        );
        // navigate("/app/manage/users/list");
      }
    }
  };

  /* Method to get Tasks */
  const getTasksData = async () => {
    try {
      const response = await dispatch(
        getAllTasks({ query: { actor: "officeAdmin" } })
      ).unwrap();
      if (response?.length > 0) {
        setTasks(response);
      } else {
        setTasks([]);
      }
    } catch (error) {}
  };

  /* method to get Introducers */
  const getIntroducersData = async () => {
    try {
      const response = dispatch(getIntroducer());
    } catch (error) {}
  };

  /* Method to get Consultant */
  const getConsultantData = async () => {
    try {
      const response = dispatch(getConsultant());
    } catch (error) {}
  };

  /* Methond to handle image upload */
  const handleImageUpload = (e) => {
    setUserImage(e.target.files[0]);
  };

  // Get Campaigns data
  const getCampaigns = async () => {
    try {
      const lookupKeys = ["Source Type"];
      const response = await dispatch(
        getLookups({ searchStrings: JSON.stringify(lookupKeys) })
      );
      if (response?.payload?.[0]?.Lookup?.length > 0) {
        setCampaigns(response?.payload?.[0]?.Lookup);
      } else {
        setCampaigns([]);
      }
    } catch (error) {}
  };

  // Method to change checkbox for is user access
  const handleChangeIsUserAccess = (checked) => {
    setUserData({
      ...userData,
      permissions: {
        lookupId: lookups?.UserAccess?.[0]?.id,
        isPermission: checked,
      },
    });
  };

  useEffect(() => {
    getTasksData();
    getIntroducersData();
    getConsultantData();
    getCampaigns();
  }, []);

  // UseEffect to update default phone number if role === 'OfficeAdmin'
  useEffect(() => {
    if (userData?.role === USER_ROLE?.OFFICE_ADMIN) {
      setUserData({
        ...userData,
        phoneNumber: OFFICE_ADMIN_PHONE_NUMBER,
      });
    } else {
      setUserData({
        ...userData,
        phoneNumber: "",
      });
    }
  }, [userData?.role]);
  return (
    <>
      {/* Loader component */}
      {isLoader && <Loader />}

      <Grid
        border={0}
        padding={1}
        className="instruction_dashboard_scrollbar create-user"
        style={{
          padding: "1rem",
          overflow: "auto",
          height: "90vh",
        }}
        display={"flex"}
        flexDirection={"column"}
      >
        <Grid
          item
          xs={12}
          style={{ display: "flex", gap: 10, marginBottom: 2 }}
        >
          <img
            src={BackButton}
            onClick={() => navigate("/app/manage/users/list")}
            style={{ cursor: "pointer", marginLeft: "10px" }}
            alt="squiggle"
          />
          <Typography className="serif_display_regular_26 dark_grey">
            Add a New User
          </Typography>
        </Grid>
        <Grid container>
          {/* USER IMAGE*/}

          <Grid item xs={2}>
            <Paper
              onClick={() => inputRef.current.click()}
              className="createUserProfileBackground"
            >
              <div className="createUserProfileImageBackground">
                {userImage ? (
                  <img
                    src={URL.createObjectURL(userImage)}
                    className="createUserProfileImage"
                    alt="squiggle"
                  />
                ) : (
                  <img
                    src={AddPhotoIcon}
                    style={{
                      objectFit: "cover",
                    }}
                  />
                )}
              </div>
            </Paper>
            <input
              type="file"
              ref={inputRef}
              src={userImage}
              style={{ display: "none" }}
              onChange={handleImageUpload}
            />
          </Grid>

          {/* Create User Form Layout */}

          <Grid item xs={10} px={5}>
            <Grid container spacing={3}>
              {/* Salutation */}

              <Grid item xs={2}>
                <FormControl
                  fullWidth
                  required
                  error={isError && !userData?.salutation}
                >
                  <InputLabel
                    shrink={true}
                    className="normal_normal_600_12_Manropee"
                    style={{
                      lineHeight: "2.0375",
                      marginLeft: "-12px",
                      top: "3px",
                    }}
                  >
                    Salutation
                  </InputLabel>
                  <Select
                    className="formControlField"
                    value={userData?.salutation}
                    label="Salutation"
                    onChange={(e) =>
                      handleChangeFormFields(e?.target?.value, "salutation")
                    }
                    variant="standard"
                    size="small"
                  >
                    {salutation?.length > 0 &&
                      salutation?.map((d) => (
                        <MenuItem key={d?.id} value={d?.name}>
                          {d?.name}
                        </MenuItem>
                      ))}
                  </Select>
                  {isError && !userData?.salutation && (
                    <FormHelperText className="red">
                      {VALIDATION_MSG.REQUIRED}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              {/* First Name */}

              <Grid item xs={2.5}>
                <TextField
                  className="inputTextField normal_normal_600_12_Manropee"
                  label="First Name"
                  placeholder="First Name"
                  fullWidth
                  size="small"
                  required
                  disabled={userData?.type === "Internal"}
                  value={userData?.firstName}
                  onChange={(e) =>
                    handleChangeFormFields(e?.target?.value, "firstName")
                  }
                  error={isError && !userData?.firstName}
                  helperText={
                    isError && !userData?.firstName && VALIDATION_MSG.REQUIRED
                  }
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                />
              </Grid>

              {/* Middle Name */}

              <Grid item xs={2.5}>
                <TextField
                  className="inputTextField normal_normal_600_12_Manropee"
                  label="Middle Name"
                  placeholder="Middle Name"
                  fullWidth
                  size="small"
                  value={userData?.middleName}
                  onChange={(e) =>
                    handleChangeFormFields(e?.target?.value, "middleName")
                  }
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                />
              </Grid>

              {/* Last Name */}

              <Grid item xs={2.5}>
                <TextField
                  className="inputTextField normal_normal_600_12_Manropee"
                  label="Last Name"
                  placeholder="Last Name"
                  fullWidth
                  required
                  size="small"
                  value={userData?.lastName}
                  onChange={(e) =>
                    handleChangeFormFields(e?.target?.value, "lastName")
                  }
                  error={isError && !userData?.lastName}
                  helperText={
                    isError && !userData?.lastName && VALIDATION_MSG.REQUIRED
                  }
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                />
              </Grid>

              {/* Gender */}

              <Grid item xs={3}>
                <FormControl>
                  <FormLabel
                    className="normal_normal_600_12_Manrope"
                    style={{ color: "#00CE3F" }}
                  >
                    Gender
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="Male"
                    name="radio-buttons-group"
                    onChange={(e) =>
                      handleChangeFormFields(e?.target?.value, "gender")
                    }
                  >
                    {gender?.length > 0 &&
                      gender?.map((d) => (
                        <FormControlLabel
                          value={d?.name}
                          control={
                            <Radio
                              size="small"
                              sx={{
                                "&, &.Mui-checked": {
                                  color: "#00CE3F",
                                },
                                marginTop: -1,
                              }}
                            />
                          }
                          label={d?.name}
                        />
                      ))}
                  </RadioGroup>
                </FormControl>
              </Grid>

              {/* Job Title / Role */}

              <Grid item xs={2.5}>
                <FormControl
                  fullWidth
                  required
                  error={isError && !userData?.role}
                >
                  <InputLabel
                    shrink={true}
                    className="normal_normal_600_12_Manropee"
                    style={{ lineHeight: "2.0375", marginLeft: "-12px" }}
                  >
                    Job Title
                  </InputLabel>
                  <Select
                    className="formControlField"
                    value={userData?.role}
                    label="Role"
                    onChange={(e) =>
                      handleChangeFormFields(e?.target?.value, "role")
                    }
                    variant="standard"
                    size="small"
                  >
                    {roles?.length > 0 &&
                      roles?.map((d) => (
                        <MenuItem key={d?.name} value={d?.value}>
                          {d?.name}
                        </MenuItem>
                      ))}
                  </Select>
                  {isError && !userData?.role && (
                    <FormHelperText className="red">
                      {VALIDATION_MSG.REQUIRED}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              {/* Is User Access Checkbox */}
              {userData?.role === USER_ROLE?.OFFICE_ADMIN && (
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: "#00CE3F",
                          "&.Mui-checked": {
                            color: "#00CE3F",
                          },
                        }}
                        checked={userData?.permissions?.isPermission}
                        onChange={(e) =>
                          handleChangeIsUserAccess(e.target.checked)
                        }
                      />
                    }
                    label={UserAccessLabel}
                  />
                </Grid>
              )}

              {/* DOB */}

              <Grid item xs={3}>
                <FormControl fullWidth variant="standard">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      format={DATE_FORMAT}
                      slotProps={{
                        textField: {
                          variant: "standard",
                          InputLabelProps: { shrink: true },
                          size: "small",
                          helperText: dateError
                            ? VALIDATION_MSG?.INVALID_DATE
                            : "",
                        },
                      }}
                      value={userData?.dob}
                      label="Date of Birth"
                      onChange={(e) => handleChangeFormFields(e, "dob")}
                      renderInput={(params) => (
                        <TextField {...params} size="small" />
                      )}
                      onError={(newError) => setDateError(newError)}
                      className="normal_normal_600_12_Manropee outlined_border"
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>

              {/* Mobile Number */}

              <Grid item xs={3}>
                <label
                  style={{ position: "relative", bottom: 5 }}
                  className="normal_normal_600_10_Manropee"
                >
                  Work Number
                </label>
                <PhoneInput
                  country="UK"
                  defaultCountry="GB"
                  placeholder="Work Number"
                  className="inputTextField border_color normal_normal_600_12_Manropee"
                  value={userData?.phoneNumber}
                  onChange={(e) => handleChangeFormFields(e, "phoneNumber")}
                />
                {isError && !userData?.phoneNumber && (
                  <FormHelperText className="red">
                    {VALIDATION_MSG.REQUIRED}
                  </FormHelperText>
                )}
                {/* <TextField
                  className="inputTextField normal_normal_600_12_Manropee"
                  type="tel"
                  label="Mobile Number"
                  placeholder="Mobile Number"
                  fullWidth
                  required
                  size="small"
                  value={userData?.phoneNumber}
                  error={
                    (isError && !userData?.phoneNumber) ||
                    (isError && !phoneNumberValidation(userData?.phoneNumber))
                  }
                  helperText={
                    (isError &&
                      !userData?.phoneNumber &&
                      VALIDATION_MSG.REQUIRED) ||
                    (isError &&
                      !phoneNumberValidation(userData?.phoneNumber) &&
                      VALIDATION_MSG.VALID_MOBILE_NUMBER)
                  }
                  onChange={(e) =>
                    handleChangeFormFields(e?.target?.value, "phoneNumber")
                  }
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                /> */}
              </Grid>

              {/* Email */}
              <Grid item xs={3}>
                <TextField
                  className="inputTextField normal_normal_600_12_Manropee"
                  label="Email"
                  type="email"
                  placeholder="Email"
                  fullWidth
                  required
                  size="small"
                  value={userData?.email}
                  error={
                    (isError && !userData?.email) ||
                    (isError && !emailValidation(userData?.email))
                  }
                  helperText={
                    (isError && !userData?.email && VALIDATION_MSG?.REQUIRED) ||
                    (isError &&
                      !emailValidation(userData?.email) &&
                      VALIDATION_MSG.VALID_EMAIL_ID)
                  }
                  onChange={(e) =>
                    handleChangeFormFields(e?.target?.value, "email")
                  }
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                />
              </Grid>

              {/* Password */}

              <Grid item xs={2}>
                <TextField
                  className="inputTextField normal_normal_600_12_Manropee"
                  type="password"
                  label="Password"
                  placeholder="Password"
                  required
                  fullWidth
                  size="small"
                  value={userData?.password}
                  onChange={(e) =>
                    handleChangeFormFields(e?.target?.value, "password")
                  }
                  error={isError && !userData?.password}
                  helperText={
                    isError && !userData?.password && VALIDATION_MSG.REQUIRED
                  }
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                />
              </Grid>

              {/* If Role is not equal to INTRODUCER then so VAT Verified section */}
              {userData?.role !== USER_ROLE?.INTRODUCER && (
                <>
                  {/* VAT Verified */}
                  <Grid item xs={2.5}>
                    <FormControl fullWidth>
                      <InputLabel
                        className="normal_normal_600_12_Manropee"
                        shrink={true}
                        style={{ lineHeight: "2.0375", marginLeft: "-12px" }}
                      >
                        VAT Verified
                      </InputLabel>
                      <Select
                        className="formControlField"
                        value={userData?.isVatVerified}
                        label="VAT Verified"
                        onChange={(e) =>
                          handleChangeFormFields(
                            e?.target?.value,
                            "isVatVerified"
                          )
                        }
                        variant="standard"
                        size="small"
                      >
                        {IS_VAT_VERIFIED_DATA?.length > 0 &&
                          IS_VAT_VERIFIED_DATA?.map((d) => (
                            <MenuItem key={d?.id} value={d?.value}>
                              {d?.label}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {userData?.isVatVerified && (
                    <>
                      {/*  Vat Number  */}
                      <Grid ml={2} mt={2}>
                        <TextField
                          required
                          className="inputTextField normal_normal_600_12_Manropee"
                          label="VAT Number"
                          placeholder="VAT Number"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {VAT_NUMBER_START}
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) =>
                            handleChangeFormFields(
                              e?.target?.value,
                              "vatNumber"
                            )
                          }
                          sx={{ m: 1, width: "25ch" }}
                          variant="standard"
                          value={userData?.vatNumber}
                          error={
                            (isError &&
                              userData?.isVatVerified &&
                              !userData?.vatNumber) ||
                            (isError &&
                              userData?.vatNumber &&
                              !vatNumberValidation(userData?.vatNumber))
                          }
                          helperText={
                            (isError &&
                              userData?.isVatVerified &&
                              !userData?.vatNumber &&
                              VALIDATION_MSG?.REQUIRED) ||
                            (isError &&
                              userData?.vatNumber &&
                              !vatNumberValidation(userData?.vatNumber) &&
                              VALIDATION_MSG.NUM_ONLY)
                          }
                        />
                      </Grid>
                    </>
                  )}
                </>
              )}

              <Grid container gap={3} xs={12} mt={2} ml={3}>
                {/* Post Code */}

                <Grid item xs={2}>
                  <TextField
                    className="inputTextField normal_normal_600_12_Manropee"
                    label="Post Code"
                    placeholder="Post Code"
                    fullWidth
                    size="small"
                    value={userData?.address?.postalCode}
                    onChange={(e) =>
                      handleChangeAddressFields(e?.target?.value, "postalCode")
                    }
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                  />
                </Grid>

                {/* Street */}

                <Grid item xs={2}>
                  <TextField
                    className="inputTextField normal_normal_600_12_Manropee"
                    label="Street"
                    placeholder="Street"
                    fullWidth
                    size="small"
                    value={userData?.address?.street}
                    onChange={(e) =>
                      handleChangeAddressFields(e?.target?.value, "street")
                    }
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                  />
                </Grid>

                {/* City */}
                <Grid item xs={2}>
                  <TextField
                    className="inputTextField normal_normal_600_12_Manropee"
                    label="City"
                    placeholder="City"
                    fullWidth
                    size="small"
                    value={userData?.address?.city}
                    onChange={(e) =>
                      handleChangeAddressFields(e?.target?.value, "city")
                    }
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                  />
                </Grid>

                {/* State */}
                <Grid item xs={2}>
                  <TextField
                    className="inputTextField normal_normal_600_12_Manropee"
                    label="County"
                    placeholder="County"
                    fullWidth
                    size="small"
                    value={userData?.address?.state}
                    onChange={(e) =>
                      handleChangeAddressFields(e?.target?.value, "state")
                    }
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                  />
                </Grid>

                {/* Country */}
                <Grid item xs={2}>
                  <FormControl
                    fullWidth
                    required
                    error={isError && !userData?.address?.country}
                  >
                    <InputLabel
                      className="normal_normal_600_12_Manropee"
                      shrink={true}
                      style={{ lineHeight: "2.0375", marginLeft: "-12px" }}
                    >
                      Country
                    </InputLabel>
                    <Select
                      className="formControlField"
                      value={userData?.address?.country}
                      label="Country"
                      onChange={(e) =>
                        handleChangeAddressFields(e?.target?.value, "country")
                      }
                      variant="standard"
                      size="small"
                    >
                      {country?.length > 0 &&
                        country?.map((d) => (
                          <MenuItem key={d?.id} value={d?.name}>
                            {d?.name}
                          </MenuItem>
                        ))}
                    </Select>
                    {isError && !userData?.address.country && (
                      <FormHelperText className="red">
                        {VALIDATION_MSG.REQUIRED}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>

              {/*  TASKS SPECIALIZATION  */}
              {/* Only when office admin is selected */}

              {userData.role === USER_ROLE?.OFFICE_ADMIN && (
                <Grid item xs={12}>
                  <Autocomplete
                    // multiple
                    value={selectedTask || null}
                    id="tags-outlined"
                    getOptionLabel={(option) => option?.name}
                    options={tasks || []}
                    onChange={(event, value) => handleChangeTasks(value)}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        className="normal_normal_600_12_Manropee"
                        size="small"
                        label="Tasks"
                        placeholder="Select Task"
                      />
                    )}
                  />
                </Grid>
              )}

              {/* Section to show tasks only for office admin role */}
              {userData.role === USER_ROLE?.OFFICE_ADMIN && (
                <>
                  {userData?.TaskUser?.length > 0 ? (
                    <>
                      <Grid item xs={12}>
                        <Grid
                          style={{
                            border: "1px solid #dcdce1",
                            borderRadius: "6px",
                            padding: "3px",
                          }}
                        >
                          {userData?.TaskUser?.map((d, ind) => (
                            <Chip
                              style={{ margin: 6 }}
                              key="ind"
                              label={d?.name || "NA"}
                              onDelete={() => handleDeleteTask(d)}
                            />
                          ))}
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={12}>
                      <Typography>No tasks added.</Typography>
                    </Grid>
                  )}
                </>
              )}

              {/*  CONSULTANTS  */}
              {/*  only when Introducer is selected  */}

              {userData.role === USER_ROLE?.INTRODUCER && (
                <>
                  {/* Paid / unPaid section */}
                  <Grid item xs={4}>
                    <FormControl>
                      <FormLabel
                        className="normal_normal_600_12_Manrope"
                        style={{ color: "#00CE3F" }}
                      >
                        Is Commission Applicable?
                      </FormLabel>
                      <RadioGroup
                        defaultValue={true}
                        fullWidth
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        onChange={(e) =>
                          handleChangeFormFields(
                            e?.target?.value,
                            "isCommissionApplicable"
                          )
                        }
                      >
                        <FormControlLabel
                          value={true}
                          control={
                            <Radio
                              size="small"
                              sx={{
                                "&, &.Mui-checked": {
                                  color: "#00CE3F",
                                },
                                marginTop: -1,
                              }}
                            />
                          }
                          label="Paid"
                        />
                        <FormControlLabel
                          value={false}
                          control={
                            <Radio
                              size="small"
                              sx={{
                                "&, &.Mui-checked": {
                                  color: "#00CE3F",
                                },
                                marginTop: -1,
                              }}
                            />
                          }
                          label="Unpaid"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={3}>
                    <>
                      {/* VAT Verified */}
                      <FormControl fullWidth>
                        <InputLabel
                          className="normal_normal_600_12_Manropee"
                          shrink={true}
                          style={{
                            lineHeight: "2.0375",
                            marginLeft: "-12px",
                          }}
                        >
                          VAT Verified
                        </InputLabel>
                        <Select
                          disabled={
                            userData?.isCommissionApplicable === "false" ||
                            false
                          }
                          className="formControlField"
                          value={userData?.isVatVerified}
                          label="VAT Verified"
                          onChange={(e) =>
                            handleChangeFormFields(
                              e?.target?.value,
                              "isVatVerified"
                            )
                          }
                          variant="standard"
                          size="small"
                        >
                          {IS_VAT_VERIFIED_DATA?.length > 0 &&
                            IS_VAT_VERIFIED_DATA?.map((d) => (
                              <MenuItem key={d?.id} value={d?.value}>
                                {d?.label}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>

                      {userData?.isVatVerified && (
                        <>
                          {/*  Vat Number  */}
                          <TextField
                            style={{ marginTop: 10 }}
                            fullWidth
                            required
                            className="inputTextField normal_normal_600_12_Manropee"
                            label="VAT Number"
                            placeholder="VAT Number"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {VAT_NUMBER_START}
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) =>
                              handleChangeFormFields(
                                e?.target?.value,
                                "vatNumber"
                              )
                            }
                            variant="standard"
                            value={userData?.vatNumber}
                            error={
                              (isError &&
                                userData?.isVatVerified &&
                                !userData?.vatNumber) ||
                              (isError &&
                                userData?.vatNumber &&
                                !vatNumberValidation(userData?.vatNumber))
                            }
                            helperText={
                              (isError &&
                                userData?.isVatVerified &&
                                !userData?.vatNumber &&
                                VALIDATION_MSG?.REQUIRED) ||
                              (isError &&
                                userData?.vatNumber &&
                                !vatNumberValidation(userData?.vatNumber) &&
                                VALIDATION_MSG.NUM_ONLY)
                            }
                          />
                        </>
                      )}
                    </>
                  </Grid>

                  {/* Is Introducer login section */}
                  <Grid item xs={5}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: "#00CE3F",
                            "&.Mui-checked": {
                              color: "#00CE3F",
                            },
                          }}
                          checked={userData?.isLogin || false}
                          onChange={(e) =>
                            handleChangeFormFields(
                              e?.target?.checked,
                              "isLogin"
                            )
                          }
                        />
                      }
                      label="Can Introducer Login?"
                    />

                    {/* Is campaign head section */}
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: "#00CE3F",
                            "&.Mui-checked": {
                              color: "#00CE3F",
                            },
                          }}
                          checked={userData?.isCampaignHead || false}
                          onChange={(e) =>
                            handleChangeFormFields(
                              e?.target?.checked,
                              "isCampaignHead"
                            )
                          }
                        />
                      }
                      label="Is Campaign Head?"
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Autocomplete
                      value={userData?.campaignLookupId || null}
                      id="tags-outlined"
                      options={campaigns}
                      getOptionLabel={(option) => `${option?.name}`}
                      onChange={(event, value) =>
                        handleChangeFormFields(value, "campaignLookupId")
                      }
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          label="Campaign"
                          placeholder="Select Campaign"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Autocomplete
                      // multiple
                      value={selectedConsultant || null}
                      id="tags-outlined"
                      options={allConsultantArray?.concat(consultants)}
                      getOptionLabel={(option) =>
                        `${option?.firstName} ${option?.lastName}`
                      }
                      onChange={(event, value) =>
                        handleChangeConsultants(value)
                      }
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          label="Consultants"
                          placeholder="Select Consultants"
                        />
                      )}
                    />
                  </Grid>
                </>
              )}

              {userData.role === USER_ROLE?.INTRODUCER && (
                <>
                  {userData?.ConsultantName?.length > 0 ? (
                    <>
                      <Grid item xs={12}>
                        <Grid
                          style={{
                            border: "1px solid #dcdce1",
                            borderRadius: "6px",
                            padding: "3px",
                          }}
                        >
                          {userData?.ConsultantName?.map((d, ind) => (
                            <Chip
                              style={{ margin: 6 }}
                              key="ind"
                              label={`${d?.firstName} ${d?.lastName}` || "NA"}
                              onDelete={() => handleDeleteConsultant(d)}
                            />
                          ))}
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={12}>
                      <Typography>No consultant added.</Typography>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        style={{
          marginTop: "auto",
        }}
      >
        {/*  Create User Button  */}
        <Grid container spacing={2} className="Save_Button_Container">
          <Grid
            item
            xs={11}
            sx={{ display: "flex", justifyContent: "flex-end" }}
            m={1}
          >
            <Grid>
              <Button className="save_button" onClick={handleClickCreateUser}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CreateUser;
